import React, { useEffect, useState, createContext, useContext } from "react";

// Apollo Client
import { useLazyQuery, useQuery } from "@apollo/client";
import { dashboardServiceClient } from "graphql/client";
import { reportServiceClient } from "graphql/client";

// @mui material components
import {
  Autocomplete,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import {
  LocalizationProvider,
  MobileDateTimePicker,
} from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";

// Images & Icons
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { Add as AddIcon } from "@mui/icons-material";

// Graphql queries & mutations
import { BRANDS_LIST, LIST_BRAND_STORE } from "services/brand-service";
import {
  GET_EVENT_REPORT,
  GET_SALES_REPORT,
  GET_RETURN_AND_REFUND_REPORT,
  GET_STOCK_REPORT,
  GET_GMV_REPORT,
  GET_FOOTFALL_REPORT,
} from "services/report-service";

// Custom Components
import DashboardLayout from "ui/LayoutContainers/DashboardLayout";
import ReportTabs from "./reportTabs";
import StatsCards from "./statsCards";

// Material Dashboard 2 React Components
import MDButton from "components/MDButton";

// Custom Context
import { useNotification, AuthContext } from "context";

// Custome Libraries
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import * as XLSX from "xlsx";

// Setting up default timezone for dayjs operations
dayjs.extend(utc);
dayjs.extend(timezone);

// Custom Context Creation
export const ReportsFilter = createContext();

function Reports() {
  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedStore, setSelectedStore] = useState(null);
  const [startDate, setStartDate] = useState(dayjs().subtract(1, "week"));
  const [endDate, setEndDate] = useState(dayjs());
  const [tabValue, setTabValue] = useState(0);
  const [startDateError, setStartDateError] = useState(null);
  const [endDateError, setEndDateError] = useState(null);
  const [key, setKey] = useState(new Date());
  const { setNotification } = useNotification();
  const [reportType, setReportType] = useState("");

  const { user } = useContext(AuthContext);

  const {
    loading: brandListLoading,
    data: brandListData,
  } = useQuery(BRANDS_LIST, {
    client: dashboardServiceClient,
    variables: {
      take: 10,
      skip: 0,
      filter:
        user && user?.role === "admin"
          ? {
              isDeleted: false,
            }
          : {
              id: user?.userId,
            },
    },
  });

  const {
    loading: storeLoading,
    data: storeList,
  } = useQuery(LIST_BRAND_STORE, {
    client: dashboardServiceClient,
    variables: {
      listBrandStoreFilter: {
        take: 100,
        skip: 0,
        filter: {
          status: ["active", "upcoming"], // TODO: need to check if we need it hardcoded
          brand: { id: selectedBrand },
        },
      },
    },
    onError: () => {
      setSelectedStore(null); // Clear store selection on error
    },
  });

  const brandStoreList = storeList?.brandStores?.results?.map(
    (item) => item?.store
  );

  const handleDownloadTemplate = async () => {
    const token = localStorage.getItem("token");

    // URL construction based on environment and type of review
    let url;
    url =
      process.env.REACT_APP_NODE_ENV === "development"
        ? `${process.env.REACT_APP_API_BASE_URL}/report-service/export`
        : `/report-service/export`;

    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          reportType: reportType,
          filter: {
            endDate: endDate,
            startDate: startDate,
            brandId: selectedBrand,
            storeId: selectedStore,
            orderType: tabValue === 1 ? "RETURN" : "PURCHASE",
          },
        }),
      });
      if (response.ok) {
        const blob = await response.blob();

        const downloadUrl = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = `report.xlsx`; // Use a dynamic filename based on reviewType

        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(downloadUrl);
      } else {
        throw new Error("Failed to download template");
      }
    } catch (error) {
      console.error("Error downloading template:", error);
      setNotification({
        color: "error",
        isVisible: true,
        message: error?.message || "Something went wrong",
      });
    }
  };

  useEffect(() => {
    if (user && user?.role !== "admin") {
      setSelectedBrand(user?.userId);
    }
  }, [user]);

  const [
    getGmvReport,
    {
      loading: gmvLoading,
      data: gmvData,
      error: gmvError,
      refetch: gmvRefetch,
    },
  ] = useLazyQuery(GET_GMV_REPORT, {
    client: dashboardServiceClient,
  });
  const [
    getSalesReport,
    {
      loading: salesLoading,
      data: salesData,
      error: salesError,
      refetch: salesRefetch,
    },
  ] = useLazyQuery(GET_SALES_REPORT, {
    client: reportServiceClient,
  });
  const [
    getReturnRefundReport,
    {
      loading: returnLoading,
      data: returnData,
      error: returnError,
      refetch: returnRefetch,
    },
  ] = useLazyQuery(GET_RETURN_AND_REFUND_REPORT, {
    client: dashboardServiceClient,
  });
  const [
    getStockReport,
    {
      loading: stockLoading,
      data: stockData,
      error: stockError,
      refetch: stockRefetch,
    },
  ] = useLazyQuery(GET_STOCK_REPORT, {
    client: reportServiceClient,
  });
  const [
    getEventReport,
    {
      loading: eventLoading,
      data: eventData,
      error: eventError,
      refetch: eventRefetch,
    },
  ] = useLazyQuery(GET_EVENT_REPORT, {
    client: dashboardServiceClient,
  });
  const [
    getFootFallReport,
    {
      loading: footfallLoading,
      data: footfallData,
      error: footfallError,
      refetch: footfallRefetch,
    },
  ] = useLazyQuery(GET_FOOTFALL_REPORT, {
    client: dashboardServiceClient,
  });

  const handleStartDateChange = (newValue) => {
    if (newValue === null || newValue?.isAfter(dayjs())) {
      setStartDateError("Please Enter Valid Start date.");
      setStartDate(null); // Set to null if the date is invalid or cleared
    } else {
      setStartDate(dayjs(newValue).format("YYYY-MM-DDTHH:mm:ss"));
      setStartDateError(null);

      // Automatically adjust the end date to be within one month of the start date
      const newEndDate = dayjs(newValue).add(6, "month").isAfter(dayjs())
        ? dayjs() // If one month from startDate is in the future, set endDate to today
        : dayjs(newValue).add(6, "month");
      setEndDate(newEndDate?.format("YYYY-MM-DDTHH:mm:ss"));
      setEndDateError(null);
    }
  };

  const handleExportToExcel = () => {
    const flatten = (obj, parent = "", res = {}) => {
      for (let key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          const propName = parent ? `${parent}.${key}` : key;
          if (typeof obj[key] === "object" && obj[key] !== null) {
            if (Array.isArray(obj[key])) {
              obj[key].forEach((item, index) => {
                flatten(item, `${propName}[${index}]`, res);
              });
            } else {
              flatten(obj[key], propName, res);
            }
          } else {
            res[propName] = obj[key];
          }
        }
      }
      return res;
    };

    const flattenedData = footfallDataRows.map((item) => flatten(item));
    const worksheet = XLSX.utils.json_to_sheet(flattenedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    XLSX.writeFile(workbook, "data.xlsx");
  };

  const handleEndDateChange = (newValue) => {
    if (newValue === null) {
      setEndDate(null); // Set to null if the date is cleared
      setEndDateError(null);
    }
    const maxAllowedEndDate = dayjs(startDate, "YYYY-MM-DDTHH:mm:ss").add(
      6,
      "month"
    ); // Maximum allowed end date is 1 month from start date
    if (
      dayjs(newValue).isAfter(maxAllowedEndDate) ||
      dayjs(newValue).isAfter(dayjs()) ||
      dayjs(newValue).isBefore(startDate)
    ) {
      setEndDateError(
        "End date must be within one month of the start date and cannot be a future date."
      );
    } else {
      setEndDate(dayjs(newValue).format("YYYY-MM-DDTHH:mm:ss"));
      setEndDateError(null);
    }
  };

  useEffect(() => {
    setKey(new Date());
    switch (tabValue) {
      case 0:
        return setReportType("sales-report");
      case 1:
        return setReportType("sales-report");
      case 2:
        return setReportType("summary-report");
      case 3:
        const formattedStartDate = dayjs(startDate)
          .tz("Asia/Kolkata")
          .format("YYYY-MM-DDTHH:mm:ssZ");
        const formattedEndDate = dayjs(endDate)
          .tz("Asia/Kolkata")
          .format("YYYY-MM-DDTHH:mm:ssZ");
        return getFootFallReport({
          variables: {
            take: 10000,
            skip: 0,
            fromDate: formattedStartDate,
            tillDate: formattedEndDate,
            storeId: selectedStore,
            relations: ["store"],
          },
        });
      case 4:
      case 5:
      case 6:
        return console.warn("Invalid report type yet to activate");
      default:
        console.error(
          "OutofRange: tab value is not active or present: ",
          tabValue
        );
    }
  }, [tabValue, selectedBrand, selectedStore, startDate, endDate]);

  const gmvDataRows = gmvData?.getGmvReport?.data ?? [];
  const salesDataRows = salesData?.getSalesReport?.data ?? [];
  const returnRefundDataRows = salesData?.getSalesReport?.data ?? [];
  const stockDataRows = stockData?.getStockReports?.getStockReports ?? [];
  const eventDataRows = eventData?.getEventReports?.data;
  const footfallDataRows = footfallData?.Footfalls?.results?.footFall;
  const footfallDataStats = [
    {
      label: "Total Visitors",
      value: footfallData?.Footfalls?.results?.totalFootfall,
      fromTime: dayjs(startDate).format("DD-MM-YYYY"),
      toTime: dayjs(endDate).format("DD-MM-YYYY"),
    },
  ];

  return (
    <DashboardLayout>
      <ReportsFilter.Provider
        value={{
          selectedBrand,
          selectedStore,
          startDate,
          endDate,
          tabValue,
          getStockReport,
          getSalesReport,
          key,
        }}
      >
        <Grid container mt={2}>
          <Grid container display={"flex"} justifyContent={"space-between"}>
            <Typography
              sx={{
                fontFamily: "montserrat",
                color: "#000",
                fontWeight: "700",
              }}
              ml={1}
            >
              Reports
            </Typography>

            {[0, 1, 3].includes(tabValue)  && (
              <Grid item xs={10} display="flex" gap={2}>
                {user && user?.role === "admin" && (
                  <Grid item xs={2}>
                    <Autocomplete
                      labelId="demo-simple-select-label"
                      label="Select Brand"
                      id="demo-simple-select"
                      name="brand"
                      loading={brandListLoading}
                      iconComponent={() => (
                        <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                      )}
                      options={
                        brandListData?.brandlist?.results?.length
                          ? brandListData?.brandlist?.results.map((item) => ({
                              label: item?.name,
                              value: item?.id,
                            }))
                          : []
                      }
                      onChange={(e, newValue) => {
                        setSelectedBrand(newValue?.value || null);
                        setSelectedStore(null);
                      }}
                      sx={{
                        "& .MuiInputBase-root": {
                          height: 40,
                          borderRadius: "20px",
                          px: 2,
                          backgroundColor: "white.main",
                        },
                      }}
                      renderInput={(params) => (
                        <TextField {...params} label="Select Brand" />
                      )}
                    />
                  </Grid>
                )}
                <Grid item xs={2}>
                  <Autocomplete
                    key={selectedBrand}
                    labelId="demo-simple-select-label"
                    label="Select Store"
                    id="demo-simple-select"
                    name="store"
                    loading={storeLoading}
                    disabled={!selectedBrand}
                    iconComponent={() => (
                      <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                    )}
                    options={
                      brandStoreList?.length
                        ? brandStoreList
                            .filter((item) => item?.status === "Active")
                            .map((item) => ({
                              label: item.name,
                              value: item.id,
                            }))
                        : []
                    }
                    onChange={(e, newValue) => {
                      setSelectedStore(newValue?.value);
                      if (!selectedBrand) {
                        setSelectedStore(null);
                      }
                    }}
                    sx={{
                      "& .MuiInputBase-root": {
                        height: 40,
                        borderRadius: "20px",
                        px: 2,
                        backgroundColor: "white.main",
                      },
                    }}
                    renderInput={(params) => (
                      <TextField {...params} label="Select Store" />
                    )}
                  />
                </Grid>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Grid item xs={2}>
                    <MobileDateTimePicker
                      label="Start Date & Time"
                      ampm={true} // Enable AM/PM view
                      views={["year", "month", "day", "hours", "minutes"]} // Views with hours and minutes for time selection
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock, // Clock for seconds if needed
                      }}
                      format="DD/MM/YYYY hh:mm A"
                      value={dayjs(startDate, "YYYY-MM-DDTHH:mm:ss")}
                      onChange={handleStartDateChange}
                      maxDate={dayjs()}
                      disableFuture
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(startDateError)}
                          helperText={startDateError || ""}
                        />
                      )}
                    />
                    {startDateError && (
                      <Typography
                        variant="body2"
                        color="error"
                        sx={{ mt: 1, fontSize: "10px" }}
                      >
                        {startDateError}
                      </Typography>
                    )}
                  </Grid>

                  <Grid item xs={2}>
                    <MobileDateTimePicker
                      label="End Date & Time"
                      ampm={true} // Enable AM/PM view
                      views={["year", "month", "day", "hours", "minutes"]} // Views with hours and minutes for time selection
                      viewRenderers={{
                        hours: renderTimeViewClock,
                        minutes: renderTimeViewClock,
                        seconds: renderTimeViewClock, // Clock for seconds if needed
                      }}
                      format="DD/MM/YYYY hh:mm A"
                      value={dayjs(endDate, "YYYY-MM-DDTHH:mm:ss")}
                      minDate={dayjs(startDate, "YYYY-MM-DDTHH:mm:ss")}
                      maxDate={
                        dayjs(startDate, "YYYY-MM-DDTHH:mm:ss")
                          .add(6, "month")
                          .isAfter(dayjs())
                          ? dayjs()
                          : dayjs(startDate, "YYYY-MM-DDTHH:mm:ss").add(
                              6,
                              "month"
                            )
                      }
                      disabled={!startDate}
                      onChange={handleEndDateChange}
                      disableFuture
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          error={Boolean(endDateError)}
                          helperText={endDateError || ""}
                        />
                      )}
                    />
                    {endDateError && (
                      <Typography
                        variant="body2"
                        color="error"
                        sx={{ mt: 1, fontSize: "10px" }}
                      >
                        {endDateError}
                      </Typography>
                    )}
                  </Grid>
                </LocalizationProvider>
                {(tabValue === 0 || tabValue === 1 || tabValue === 3) && (
                  <Grid item xs={2} sx={{ textAlign: "end" }}>
                    <MDButton
                      type="button"
                      variant="outlined"
                      color="dark"
                      sx={{
                        fontFamily: "Montserrat",
                        fontSize: "15px",
                        fontWeight: 400,
                        pb: 2,
                      }}
                      style={{ margin: "0px" }}
                      circular={true}
                      startIcon={<AddIcon />}
                      onClick={
                        tabValue === 0 || tabValue === 1 
                          ? handleDownloadTemplate
                          : handleExportToExcel
                      }
                    >
                      Export
                    </MDButton>
                  </Grid>
                )}
              </Grid>
            )}
          </Grid>

          {([0, 1, 3].includes(tabValue)) && (
            <Grid item xs={12} display="flex">
              <StatsCards tabValue={tabValue} displayData={footfallDataStats} />{" "}
            </Grid>
          )}

          <Grid item xs={12} mt={2}>
            <ReportTabs
              tabValue={tabValue}
              setTabValue={setTabValue}
              gmvDataRows={gmvDataRows}
              salesDataRows={salesDataRows}
              returnRefundDataRows={returnRefundDataRows}
              stockDataRows={stockDataRows}
              eventDataRows={eventDataRows}
              footfallDataRows={footfallDataRows}
            />
          </Grid>
        </Grid>
      </ReportsFilter.Provider>
    </DashboardLayout>
  );
}

export default Reports;
