import React, { createContext, useContext, useRef, useState } from "react";
import { Grid, Box, Icon, Button, Drawer, Typography } from "@mui/material";
// import StarIcon from '@mui/icons-material/Star';
// import { ReactComponent as StarIcon } from "assets/images/StarIcon.svg";
import { Add as AddIcon } from "@mui/icons-material";
import MDButton from "components/MDButton";
import StoreAgreementLandingPage from "./storeAgreementLandingPage";
import StoreAgreementForm from "./storeAgreementForm";

export const GridContext = createContext();
export const useGridContext = () => useContext(GridContext);

function StoreAgreement({ brandData, tabValue, setTabValue }) {
  const gridRef = useRef(); // Create the ref for the grid
  const [open, setOpen] = useState(false);
  const [selectedStoreAgreement, setSelectedStoreAgreement] = useState(null);

  const handleRowClick = (params) => {
    setSelectedStoreAgreement(params.data);
    handleOpen();
  };
  const ControlSection = ({ user, setOpen, setSelectedStoreAgreement }) =>
    user && user?.role === "admin" ? (
      <>
        <Grid item xs={12} sm={6} md={6} />
        <Grid item xs={12} sm={6} md={6} mb={2} textAlign="right">
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <MDButton
              type="button"
              variant="contained"
              color="black"
              sx={{
                fontFamily: "Montserrat",
                fontSize: "15px",
                fontWeight: 400,
                marginBottom: 2,
              }}
              circular={true}
              onClick={() => {
                setOpen(true);
                setSelectedStoreAgreement(null);
              }}
              startIcon={<AddIcon />}
            >
              Assign Store
            </MDButton>
          </Box>
        </Grid>
      </>
    ) : (
      <></>
    );
  const CustomerDrawer = ({ open, handleClose, selectedStoreAgreement }) => (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { width: "50%" } }}
    >
      <Grid
        container
        sx={{
          backgroundColor: "#FFFFFF",
          borderBottom: 1,
          borderColor: "#FAFAFA",
          pb: 3,
          pt: 2,
          pl: "5px",
        }}
      >
        <Grid item xs={12}>
          &nbsp;
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ display: "flex", alignItems: "center", mx: 2 }}
        >
          <Icon fontSize="large">star</Icon>
          <Typography
            variant="h5"
            fontFamily={"Montserrat"}
            fontSize={24}
            sx={{ ml: 2 }}
          >
            Assign Store
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{ width: "100%", minHeight: "70vh" }}>
        <StoreAgreementForm
          agreementData={selectedStoreAgreement}
          handleClose={handleClose}
          brandData={brandData}
        />
      </Box>
    </Drawer>
  );
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const user =
    localStorage.getItem("userDetails") &&
    localStorage.getItem("userDetails") !== "undefined"
      ? JSON.parse(localStorage.getItem("userDetails"))
      : {};

  return (
    <GridContext.Provider value={{ externalGridRef: gridRef }}>
      <Grid>
        <Grid container display="flex">
          <Grid item xs={6}>
            <Typography> Store Agreement</Typography>
          </Grid>
          <Grid item xs={6} display="flex">
            <ControlSection setOpen={handleOpen} user={user} setSelectedStoreAgreement={setSelectedStoreAgreement} />
          </Grid>
        </Grid>
        <Grid>
          <StoreAgreementLandingPage
            onRowClick={handleRowClick}
            brandData={brandData}
          />
          {user && user.role === "admin" && (
            <CustomerDrawer
              open={open}
              handleClose={handleClose}
              selectedStoreAgreement={selectedStoreAgreement}
            />
          )}
        </Grid>
      </Grid>
      <Grid m={2} sx={{ display: "flex", justifyContent: "end" }}>
        <MDButton
          type="submit"
          variant="contained"
          circular={true}
          color="black"
          onClick={() => {
            setTabValue(5);
          }}
        >
          Next
        </MDButton>
      </Grid>
    </GridContext.Provider>
  );
}

export default StoreAgreement;
