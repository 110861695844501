import { Autocomplete, TextField } from '@mui/material';
import React, { useState } from 'react';

export const DropdownFilterComponent = ({ model, onModelChange, options, label, id }) => {
    const [selected, setSelected] = useState(model?.filter);

    const onChange = (selectedOption) => {
        setSelected(selectedOption?.value);
        onModelChange({...model, filter: selectedOption?.value || null})
    }

    return (
        <Autocomplete
          onChange={(event,newValue)=>onChange(newValue)}
          // disablePortal
          id={id}
          options={options}
          value={selected}
          sx={{ "& .MuiInputBase-root":{height: 40, borderRadius: "20px", px: 2, backgroundColor: "white.main"} }}
          renderInput={(params) => <TextField {...params} label={label} />}
        />
    );
};