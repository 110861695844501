import React, { useEffect, useState, useContext } from "react";

// Formik & Yup React Form Validation
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';

// @mui material components
import {
  Button,
  Grid,
  TextField,
  MenuItem,
  Typography,
  Select,
  FormControl,
  InputLabel
} from "@mui/material";

// Images & Icon
import { ReactComponent as ArrowDropDownIcon } from "assets/images/Arrow-Down.svg";

// Material Dashboard 2 React components
import FileUploader from "components/MDFileUpload";
import Loader from "components/MDLoader";

// Apollo Client 
import { useLazyQuery, useMutation } from "@apollo/client";
import { dashboardServiceClient } from "graphql/client";


// Graphql query & mutation
import { 
  GET_VENDOR_TAB_DETAILS_BY_ID, 
  CREATE_BRAND_METADATA, 
  UPDATE_BRAND_METADATA 
} from "services/brand-service";

// Custom Context
import { useNotification, AuthContext } from 'context';

// Yup Form Validation Schema
const validationSchema = Yup.object({
  bankName: Yup.string().required('Bank Name is required'),
  bankAccountNo: Yup.string()
    .matches(/^[0-9]{9,18}$/, 'Bank Account No. must be 9-18 digits')
    .required('Bank Account No. is required'),
  bankIFSCCode: Yup.string()
    .matches(/^[A-Z]{4}0[A-Z0-9]{6}$/, 'Invalid IFSC Code')
    .required('Bank IFSC Code is required'),
  bankAddress: Yup.string().required('Bank Address is required'),
  branch: Yup.string().required('Branch is required'),
  city: Yup.string().required('City is required'),
  state: Yup.string().required('State is required'),
  bankAccountType: Yup.string().required('Bank Account Type is required'),
  micrNo: Yup.string()
    .matches(/^[0-9]{9}$/, 'MICR No. must be 9 digits')
    .required('MICR No. is required'),
  cancelledChequePath: Yup.string().required('Cancelled Cheque is required'),
  cancelledChequeUrl: Yup.string().required('Cancelled Cheque is required'),

});

/**
 * 
 * @param {*} vendorDetail desc: payload details
 * @param {*} setNotification desc: user feedback notification
 * @param {*} handleTabChange desc: callback function to chnage tab value to next
 * @param {*} refetchQuery desc: query to refetch after successful mutation
 * @returns handleSubmit callback function (values) => { implementation of submit function }
 */
const useMetadataMutation = (
  vendorDetail,
  setNotification,
  handleTabChange,
  refetchQuery
) => {
  const brandMetadata = vendorDetail?.brandMetaData?.find((data) => !data.isDeleted && data.key === "BankDetails") || {};
  const mutationOptions = {
    client: dashboardServiceClient,
    refetchQuery
  };

  const [createBrandMetadataMutation] = useMutation(
    CREATE_BRAND_METADATA,
    mutationOptions
  );
  const [updateBrandMetadataMutation] = useMutation(
    UPDATE_BRAND_METADATA,
    mutationOptions
  );

  const handleSubmit = (values) => {
    const mutation =
      brandMetadata && brandMetadata.id
        ? updateBrandMetadataMutation
        : createBrandMetadataMutation;
    const mutationInput =
      brandMetadata && brandMetadata.id
        ? {
          metaDataInput: {
            id: brandMetadata.id,
            key: "BankDetails",
            value: { ...brandMetadata.value, ...values },
          },
        }
        : {
          metaDataInput: {
            brandId: vendorDetail.id,
            key: "BankDetails",
            value: { ...values },
          },
        };

    mutation({ variables: mutationInput })
      .then(() => {
        handleTabChange(null, 4);
        setNotification({
          color: "success",
          isVisible: true,
          message:
            brandMetadata && brandMetadata.id
              ? "Brand Bank data updated successfully"
              : "Brand Bank data created successfully",
        });
      })
      .catch((error) => {
        console.error(" Error:", error?.message);
        setNotification({
          color: "error",
          isVisible: true,
          message: error?.message || "Something went wrong",
        });
      });
  };

  return handleSubmit;
};

const BankDetailsForm = ({ handleTabChange, brandData }) => {
  const { setNotification } = useNotification();
  const { user } = useContext(AuthContext);

  const [vendorDetail, setVendorDetail] = useState(null);
  const [comapanyDetail, setCompanyDetail] = useState(null);

  const [getVendorDetail, {
    loading: vendorDetailLoading, 
    error: vendorDetailError,
    refetch: vendorDetailRefetch
  }] = useLazyQuery(GET_VENDOR_TAB_DETAILS_BY_ID, {
    client: dashboardServiceClient,
    onCompleted: (response) => {
      const vendorDetail = response.brandlist.results[0];
      const companyData = vendorDetail?.brandMetaData.find((data) => data.key === "BankDetails") || {};
      setVendorDetail(vendorDetail);
      setCompanyDetail(companyData);
    },
    onError: (error) => {
      console.error(error)
      setNotification({
        color: "error",
        isVisible: true,
        message:"Error: fetching company details",
      });
    }
  })

  useEffect(() => {
    if (user) {
      getVendorDetail({"variables": {
        "take": 1,
        "skip": 0,
        "filter": {
          "id": brandData ? brandData?.id : user.userId,
          "brandMetaData": {
            "key": "BankDetails"
          }
        }
      }});
    }
  }, [user])
  
  const handleSubmit = useMetadataMutation(
    vendorDetail || { id: brandData ? brandData?.id : user.userId }, // * if vendor is undefined incase for new entry
    setNotification,
    handleTabChange,
    vendorDetailRefetch
  );

  if(vendorDetailLoading) return <Loader />
  if(vendorDetailError) return <>Error: {vendorDetailError.message}</>
  
  return (
    <Formik
      enableReinitialize={true}
      initialValues={{
        bankName: comapanyDetail?.value?.bankName || "",
        bankAccountNo: comapanyDetail?.value?.bankAccountNo || "",
        bankIFSCCode: comapanyDetail?.value?.bankIFSCCode || "",
        bankAddress: comapanyDetail?.value?.bankAddress || "",
        branch: comapanyDetail?.value?.branch || "",
        city: comapanyDetail?.value?.city || "",
        state: comapanyDetail?.value?.state || "",
        bankAccountType: comapanyDetail?.value?.bankAccountType || "",
        micrNo: comapanyDetail?.value?.micrNo || "",
        cancelledChequePath:
          comapanyDetail?.value?.cancelledChequePath ||
          comapanyDetail?.value?.cancelledCheque ||
          "",
        cancelledChequeUrl: comapanyDetail?.value?.cancelledChequeUrl || "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => handleSubmit(values)}
    >
      {({ setFieldValue, values, errors, touched, resetForm,dirty }) => {

        useEffect(() => {
          return () => {
            // * will check if unsave data then show warning data loss
            // TODO: implement confirmation block to save or discard data
            if(dirty) {
              setNotification({
                color: "warning",
                isVisible: true,
                message:"Warning: Unsaved data was lost",
              });
            }
          }
        }, [dirty])

        return (
          <Form>
            <Grid container spacing={2}>
              <Grid item xs={12} mt={2}>
                <Field
                  as={TextField}
                  name="bankName"
                  label="Bank Name"
                  fullWidth
                  error={touched.bankName && Boolean(errors.bankName)}
                  helperText={touched.bankName && errors.bankName}
                  inputProps={{
                    style: { fontFamily: "Montserrat" },
                  }}
                  InputLabelProps={{
                    style: { fontFamily: "Montserrat" },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  as={TextField}
                  name="bankAccountNo"
                  label="Bank Account No."
                  fullWidth
                  error={touched.bankAccountNo && Boolean(errors.bankAccountNo)}
                  helperText={touched.bankAccountNo && errors.bankAccountNo}
                  inputProps={{
                    style: { fontFamily: "Montserrat" },
                  }}
                  InputLabelProps={{
                    style: { fontFamily: "Montserrat" },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  as={TextField}
                  name="bankIFSCCode"
                  label="Bank IFSC Code"
                  fullWidth
                  error={touched.bankIFSCCode && Boolean(errors.bankIFSCCode)}
                  helperText={touched.bankIFSCCode && errors.bankIFSCCode}
                  inputProps={{
                    style: { fontFamily: "Montserrat" },
                  }}
                  InputLabelProps={{
                    style: { fontFamily: "Montserrat" },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  as={TextField}
                  name="bankAddress"
                  label="Bank Address"
                  fullWidth
                  error={touched.bankAddress && Boolean(errors.bankAddress)}
                  helperText={touched.bankAddress && errors.bankAddress}
                  inputProps={{
                    style: { fontFamily: "Montserrat" },
                  }}
                  InputLabelProps={{
                    style: { fontFamily: "Montserrat" },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Field
                  as={TextField}
                  name="branch"
                  label="Branch"
                  fullWidth
                  error={touched.branch && Boolean(errors.branch)}
                  helperText={touched.branch && errors.branch}
                  inputProps={{
                    style: { fontFamily: "Montserrat" },
                  }}
                  InputLabelProps={{
                    style: { fontFamily: "Montserrat" },
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <Field
                  as={TextField}
                  name="city"
                  label="City"
                  fullWidth
                  error={touched.city && Boolean(errors.city)}
                  helperText={touched.city && errors.city}
                  inputProps={{
                    style: { fontFamily: "Montserrat" },
                  }}
                  InputLabelProps={{
                    style: { fontFamily: "Montserrat" },
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <Field
                  as={TextField}
                  name="state"
                  label="State"
                  fullWidth
                  error={touched.state && Boolean(errors.state)}
                  helperText={touched.state && errors.state}
                  inputProps={{
                    style: { fontFamily: "Montserrat" },
                  }}
                  InputLabelProps={{
                    style: { fontFamily: "Montserrat" },
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel
                    id="bankAccountType"
                    sx={{ fontFamily: "Montserrat" }}
                  >
                    Bank Account Type
                  </InputLabel>
                  <Field
                    as={Select}
                    name="bankAccountType"
                    label="Bank Account Type"
                    fullWidth
                    IconComponent={() => (
                      <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                    )}
                    sx={{ height: "40px", fontFamily: "Montserrat" }}
                    error={touched.bankAccountType && !!errors.bankAccountType}
                    helpertext={
                      touched.bankAccountType ? errors.bankAccountType : ""
                    }
                  >
                    <MenuItem
                      value="Current Account"
                      sx={{ fontFamily: "Montserrat" }}
                    >
                      Current Account
                    </MenuItem>
                    <MenuItem
                      value="Savings Account"
                      sx={{ fontFamily: "Montserrat" }}
                    >
                      Savings Account
                    </MenuItem>
                  </Field>
                  {touched?.bankAccountType && errors?.bankAccountType && (
                    <Typography fontSize={12} color="error">
                      {errors?.bankAccountType}
                    </Typography>
                  )}
                </FormControl>
              </Grid>

              <Grid item xs={6}>
                <Field
                  as={TextField}
                  name="micrNo"
                  label="MICR No."
                  fullWidth
                  error={touched.micrNo && Boolean(errors.micrNo)}
                  helperText={touched.micrNo && errors.micrNo}
                  inputProps={{
                    style: { fontFamily: "Montserrat" },
                  }}
                  InputLabelProps={{
                    style: { fontFamily: "Montserrat" },
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography>
                  <span
                    style={{
                      fontWeight: "bold",
                      color: "#000",
                      fontFamily: "Montserrat",
                      fontSize: "15px",
                    }}
                  >
                    Cancelled Cheque
                  </span>
                  <span
                    style={{
                      color: "#9E9E9E",
                      fontSize: "12px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    {" "}
                    Please upload JPEG, PNG or PDF files only.
                  </span>
                </Typography>
                <FileUploader
                  hasDisplayName
                  isMultiple={false}
                  isImage={true}
                  isPdf={true}
                  fieldName={"Cancel Cheque Upload"}
                  preLoadedFile={
                    !!values.cancelledChequeUrl
                      ? [
                          {
                            fileName: values.cancelledChequeUrl,
                            displayName: values.cancelledChequePath,
                          },
                        ]
                      : null
                  }
                  signedUrlCallback={(newUrl, index, publicUrl) => {
                    setFieldValue("cancelledChequePath", newUrl);
                    setFieldValue("cancelledChequeUrl", publicUrl);
                  }}
                  isFieldValid={
                    touched?.cancelledChequeUrl && errors?.cancelledChequeUrl
                  }
                />
                {touched.cancelledChequeUrl && errors.cancelledChequeUrl && (
                  <Typography fontSize={12} color="error">
                    {errors.cancelledChequeUrl}
                  </Typography>
                )}
              </Grid>

              <Grid item xs={12}>
                <Grid
                  container
                  sx={{ mt: 3 }}
                  justifyContent={"flex-end"}
                  gap={3}
                >
                  <Button
                    sx={{
                      color: "#000000",
                      borderRadius: "50px",
                      borderColor: "#000000",
                      fontFamily: "Montserrat",
                    }}
                    variant="outlined"
                    onClick={() =>
                      resetForm({
                        values: {
                          bankName: "",
                          bankAccountNo: "",
                          bankIFSCCode: "",
                          bankAddress: "",
                          branch: "",
                          city: "",
                          state: "",
                          bankAccountType: "",
                          micrNo: "",
                          cancelledChequePath: "",
                          cancelledChequeUrl: "",
                        },
                      })
                    }
                  >
                    Reset
                  </Button>
                  <Button
                    type="submit"
                    variant="contained"
                    style={{
                      marginRight: "10px",
                      backgroundColor: "#000000",
                      color: "#fff",
                      borderRadius: "50px",
                      fontFamily: "Montserrat",
                    }}
                  >
                    Save
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

export default BankDetailsForm;
