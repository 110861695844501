import React from "react";

// @mui material components
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Custom Components
import ReportTables from "./reportTables";
import ExcelToAgGrid from "./visualisation";
import {
  headerEventReport,
  headerGMVREPORT,
  headerReturnAndRefund,
  headerSalesReport,
  headerStockReport,
  headerFootfallReport,
} from "./header";

// Material Dashboard 2 React Components
import MDTabPanel from "components/MDTabPanel";


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function ReportTabs({
  tabValue,
  setTabValue,
  gmvDataRows,
  salesDataRows,
  returnRefundDataRows,
  stockDataRows,
  eventDataRows,
  footfallDataRows,
}) {
  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <>
      <Tabs
        sx={{ px: 2 }}
        value={tabValue}
        onChange={handleChange}
        aria-label="ant example"
      >
        <Tab
          sx={{ fontSize: "13px" }}
          label="Sales Reports"
          {...a11yProps(0)}
        />
        <Tab
          sx={{ fontSize: "13px" }}
          label="Return and Refund Status"
          {...a11yProps(1)}
        />
        <Tab
          sx={{ fontSize: "13px" }}
          label="Summary Report"
          {...a11yProps(2)}
        />
        <Tab sx={{ fontSize: "13px" }} label="Footfall" {...a11yProps(3)} />
        {/* <Tab sx={{ fontSize: "13px" }} label="Stock Report" {...a11yProps(4)} /> */}
        {/* <Tab sx={{ fontSize: "13px" }} label="Event Report" {...a11yProps(5)} /> */}
        {/* <Tab sx={{ fontSize: "13px" }} label="GMV Reports" {...a11yProps(6)} /> */}
      </Tabs>
      <MDTabPanel value={tabValue} index={0}>
        <ReportTables rowData={salesDataRows} columnDefs={headerSalesReport} />
      </MDTabPanel>
      <MDTabPanel value={tabValue} index={1}>
        <ReportTables rowData={salesDataRows} columnDefs={headerSalesReport} />
      </MDTabPanel>
      <MDTabPanel value={tabValue} index={2}>
        <ExcelToAgGrid />
      </MDTabPanel>
      <MDTabPanel value={tabValue} index={3}>
        <ReportTables
          rowData={footfallDataRows}
          columnDefs={headerFootfallReport}
        />
      </MDTabPanel>
      {/* <MDTabPanel value={tabValue} index={4}>
        <ReportTables rowData={stockDataRows} columnDefs={headerStockReport} />
      </MDTabPanel> */}
      {/* <MDTabPanel value={tabValue} index={5}>
        <ReportTables rowData={eventDataRows} columnDefs={headerEventReport} />
      </MDTabPanel> */}
      {/* <MDTabPanel value={tabValue} index={6}>
        <ReportTables rowData={gmvDataRows} columnDefs={headerGMVREPORT} />
      </MDTabPanel> */}
    </>
  );
}
