import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Grid, IconButton, Icon } from "@mui/material";
import { useQuery, useMutation } from "@apollo/client";
import { ReactComponent as deleteIcon } from "assets/images/deleteIcon.svg";
import { dashboardServiceClient, vmServiceClient } from "graphql/client";
import Loader from "components/MDLoader";
import { SearchContext } from "context";
import { useContext, useEffect, useState } from "react";
import dayjs from "dayjs";
import {
  GET_ASSET_MAPPING_LIST,
  GET_STORE_DETAILS,
} from "services/brand-service";
import { REMOVE_BRAND_DEVICE_FIXTURE_MAPPING } from "services/brand-service";
import AgGrid from "components/MDGrid";
import { AuthContext } from "context";

function AssetMapListingPage({ onRowClick, brandData, isMapFormOpen }) {
  const { user } = useContext(AuthContext);

  const getDeviceOrFixtureValue = (params, field) => {
    const deviceValue = params?.data?.devices[0]?.store?.[field];
    const fixtureValue = params?.data?.fixtures[0]?.store?.[field];
    return deviceValue || fixtureValue;
  };

  const columns = [
    {
      field: "storeId",
      headerName: "Store",
      width: 200,
      valueGetter: (params) => getDeviceOrFixtureValue(params, "name"),
    },
    {
      field: "location",
      headerName: "Location",
      width: 150,
      valueGetter: (params) =>
        params?.data?.fixtures[0]?.store?.location?.name ||
        params?.data?.devices[0]?.store?.location?.name,
    },
    {
      field: "subLoaction",
      headerName: "Sub-Location",
      width: 130,
      valueGetter: (params) =>
        params?.data?.devices[0]?.store?.subLocation?.name ||
        params?.data?.fixtures[0]?.store?.subLocation?.name,
    },
    {
      field: "type",
      headerName: "Type",
      width: 130,
      valueGetter: (params) =>
        params?.data?.devices[0]?.store?.assetType?.batchCode ||
        params?.data?.fixtures[0]?.store?.assetType?.batchCode,
    },
    {
      field: "typeId",
      headerName: "Type ID",
      width: 130,
      valueGetter: (params) =>
        params?.data?.devices[0]?.store?.assetType?.code ||
        params?.data?.fixtures[0]?.store?.assetType?.code,
    },
    {
      field: "assets",
      headerName: "Assets",
      width: 230,
      valueGetter: (params) => {
        const { devices, fixtures } = params?.data;
        const hasDevices = devices.length > 0;
        const hasFixtures = fixtures.length > 0;

        if (hasDevices && hasFixtures) {
          return "DEVICES, FIXTURES";
        } else if (hasDevices) {
          return "DEVICES";
        } else if (hasFixtures) {
          return "FIXTURES";
        }
        return null;
      },
    },
    {
      field: "assetType",
      headerName: "Asset Type",
      width: 130,
      valueGetter: (params) => {
        const { devices, fixtures } = params?.data;
        const hasSharedDevice = devices.some((device) => device?.isShared);
        const hasSharedFixture = fixtures.some((fixture) => fixture?.isShared);

        return hasSharedDevice || hasSharedFixture ? "SHARED" : "DEDICATED";
      },
    },
    {
      field: "fixtures",
      headerName: "Fixtures",
      width: 130,
      valueGetter: (params) => params?.data?.fixtures.length,
    },
    {
      field: "start",
      headerName: "Start Date",
      width: 200,
      valueGetter: (params) =>
        params?.data.start
          ? dayjs(params?.data?.start).format("DD-MM-YYYY")
          : "N/A",
    },
    {
      field: "end",
      headerName: "End Date",
      width: 200,
      valueGetter: (params) =>
        params?.data.end
          ? dayjs(params?.data?.end).format("DD-MM-YYYY")
          : "N/A",
    },
    ...(user && user.role === "admin"
      ? [
          {
            field: "actions",
            headerName: "Actions",
            width: 140,
            sortable: false,
            cellRenderer: (params) => (
              <IconButton aria-label="delete">
                <Icon
                  component={deleteIcon}
                  onClick={(e) => handleDeleteClick(e, params?.data?.uuid)}
                  fontSize="small"
                />
              </IconButton>
            ),
          },
        ]
      : []),
  ];

  const [isLoading, setIsLoading] = React.useState(false);

  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
  });

  const { loading, data, error, refetch } = useQuery(GET_ASSET_MAPPING_LIST, {
    client: vmServiceClient,
    variables: {
      page: paginationModel.page + 1,
      limit: paginationModel.pageSize,
      sortBy: "updatedAt",
      order: "DESC",
      relations: ["devices", "fixtures", "fixtures.mapDeviceFixtureBrand"],
      filter: {
        brandId: brandData ? brandData?.id : user.userId,
        fixtures: {
          isDeleted: false,
        },
      },
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: async (data) => {
      setRowCount(data?.findAllMapDeviceFixtureBrands?.total);
    },
  });

  useEffect(() => {
    if(!isMapFormOpen) {
      // call when drawer is closed
      refetch()
    }
  }, [isMapFormOpen])

  const [rowCount, setRowCount] = React.useState(0);
  const [removeAssetMapping, { loading: removing, error: removeError }] =
    useMutation(REMOVE_BRAND_DEVICE_FIXTURE_MAPPING, {
      client: vmServiceClient,
      refetchQueries: [
        {
          query: GET_ASSET_MAPPING_LIST,
          variables: {
            page: paginationModel.page + 1,
            limit: paginationModel.pageSize,
            sortBy: "updatedAt",
            order: "DESC",
            relations: [
              "devices",
              "fixtures",
              "fixtures.mapDeviceFixtureBrand",
            ],
            filter: {
              brandId: brandData ? brandData?.id : user.userId,
            },
          },
          fetchPolicy: "network-only",
        },
      ],
    });
  const handleDeleteClick = async (e, deviceId) => {
    e.stopPropagation();
    setIsLoading(true);
    try {
      await removeAssetMapping({
        variables: { id: deviceId },
      });
      await refetch();
    } catch (error) {
      console.error("Failed to remove device:", error);
    } finally {
      setIsLoading(false);
    }
  };
  React.useEffect(() => {
    setRowCount((prevRowCount) =>
      data?.findAllMapDeviceFixtureBrands?.total !== undefined
        ? data?.findAllMapDeviceFixtureBrands?.total
        : prevRowCount
    );
  }, [data?.findAllMapDeviceFixtureBrands?.total, setRowCount]);
  const handleChangePage = (event, newPage) => {
    setPaginationModel((prev) => ({ ...prev, page: newPage }));
  };
  const assets = data?.findAllMapDeviceFixtureBrands?.results;

  if (loading || isLoading) return <Loader />;
  if (error) return <p>Error: {error?.message}</p>;

  return (
    <AgGrid
      gridProps={{
        rowHeight: 60,
        defaultColDef: {
          enableRowGroup: true,
          enablePivot: true,
          floatingFilter: true,
          sortable: true,
          editable: false,
          filter: true,
          flex: 1,
          minWidth: 200,
        },
        pivotMode: false,
        sideBar: {
          toolPanels: ["columns", "filters"],
        },
        enableRangeSelection: true,
        onRowClicked: onRowClick,
      }}
      queryData={assets || []}
      columns={columns}
    />
  );
}

export default AssetMapListingPage;
