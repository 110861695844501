import { Box, Button, Typography } from "@mui/material";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "ag-grid-enterprise"; // This includes all enterprise modules
import React, { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import MDButton from "components/MDButton";
import {uploadFile} from "./uploadFile";
import { useMutation } from "@apollo/client";
import { CREATE_BULK_PRICE_UPDATE } from "services/product-service";
import { productServiceClient } from "graphql/client";
import { useNotification } from "context";

export default function BulkPriceUpdatePreview({ brand, file, fileName, callback }) {
  const [rowData, setRowData] = useState([]);
  const [columnDefs, setColumnDefs] = useState([]);
  const [hasErrors, setHasErrors] = useState([]);
  const [gridApi, setGridApi] = useState(null);
  const { setNotification } = useNotification();

  useEffect(() => {
    readFile();
  }, [file]);

  // useEffect(()=>{
  //   if(rowData && gridApi){
  //     validateAllRows(rowData)
  //   }
  // },[
  //   rowData, gridApi
  // ])

  const readFile = () => {
    const selectedFile = file;

    const reader = new FileReader();
    reader.onload = (e) => {
      const data = new Uint8Array(e.target.result);
      const workbook = XLSX.read(data, { type: "array" });

      // Assuming first sheet
      const sheetName = workbook.SheetNames[0];
      const worksheet = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName], {
        header: 1,
      });

      const headers = worksheet[0]; // Get headers from row 3
      const rows = worksheet.slice(1); // Start data after headers
      // Set column definitions for ag-Grid
      

      // Set row data for ag-Grid to show the local file data
      setRowData(
        rows.map((row) =>{
          const rowObj = headers.reduce((acc, header, idx) => {
            acc[header] = row[idx] || "";
            return acc;
          }, {})
          if(!rowObj['vendor']){
            rowObj['vendor']=brand?.name
          }
          return rowObj;
        }
        )
      );
      const colDefs = []
      if(!headers.includes("Vendor")){
        colDefs.push({
          headerName: "Vendor",
          field: "vendor",
        })
      }
      colDefs.push(...headers.map((header) => ({
        headerName: header,
        field: header,
        // width:150,
        flex:1,
        cellStyle: (params) => validateCellStyle(params), // Apply cell style validation
      })))

      if(colDefs.length > 0) {
        colDefs.push({
          headerName: "Error Message",
          field: "Errors",
          sortable: false,
          filter: false,
          pinned:'right',
          width:300,
          autoHeight: true,
          // valueGetter: ({ data }) => {
          //   return `${data.Errors || ''}`
          // },
          cellRenderer:({data})=>{
            return data.Errors? data.Errors.map(error=><Typography sx={{whiteSpace:'wrap'}} fontSize={14} fontWeight={"bold"} variant="body2" color="error">{error}</Typography>) : ''
          },
          // cellStyle: { color: "red", "white-space":"pre" },
        })
      }

      setColumnDefs(colDefs);
    };
    selectedFile && reader.readAsArrayBuffer(selectedFile);
  };

  const validateRow = (node) => {
    const errors = [];
    const errorStyles = {};
    const row = node.data;
    Object.keys(row).forEach((key) => {
      // Check if the key contains "MRP"
      if (key.includes("MRP")) {
        // Find the corresponding RSP column
        const correspondingRSPKey = key.replace("MRP", "RSP");
  
        // Check if the corresponding RSP column exists
        if (row.hasOwnProperty(correspondingRSPKey)) {
          const mrpValue = parseFloat(row[key]);
          const rspValue = parseFloat(row[correspondingRSPKey]);
  
          // Validate if MRP is greater than RSP
          if (mrpValue < rspValue) {
            errors.push(`${key} should be greater than ${correspondingRSPKey}`);
            errorStyles[key] = { color: "red" };
          }
        }
      }
    });

    const updatedRow = {
      ...row,
      Errors: errors,
      ErrorStyles: errorStyles,
    };
    node.setData(updatedRow)
    const hasErrors = gridApi?.getRenderedNodes()?.some((node) => node.data?.Errors?.length>0);
    setHasErrors(hasErrors);
  };

  // Function to validate all rows (for onGridReady)
  const validateAllRows = (nodes) => {
    nodes.forEach((node) => {
      validateRow(node);
    });
  };

  // Apply cell styling based on validation rules (for red text)
  const validateCellStyle = (params) => {
    // Check if there are any error styles for the current field in the row data
    const errorStyles = params.data?.ErrorStyles || {};
  
    // Return the style for the cell if it exists, otherwise return default style
    return errorStyles[params.colDef.field] || { color: "black" }; // Default to black text
  };

  // Trigger validation on grid ready and cell value changes
  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const onCellValueChanged = (params) => {
    validateRow(params.node);
  };

  const onFirstDataRendered = (params) => {
    validateAllRows(params.api.getRenderedNodes())
  }

  const [createBulkPriceUpdate] = useMutation(CREATE_BULK_PRICE_UPDATE, {
    client: productServiceClient,
  });

  const handleImport = () => {
    const rows = gridApi.getRenderedNodes().map(node=>node.data);
    const rowsWithError = [];
    const validRows = [];
    rows.forEach((row) => {
      if(row.Errors?.length > 0){
        rowsWithError.push({...row, Errors:JSON.stringify(row.Errors)});
      } else {
        validRows.push(row);
      }
    })

    // const headers = Object.keys(rows[0]);
    const headers = gridApi.getAllDisplayedColumns();
    const validHeaders = headers.filter(agCol=>agCol.colId!="Errors")

    // Create worksheet for valid data
    const validWorksheetData = [validHeaders.map(agCol=>agCol.userProvidedColDef.headerName), ...validRows.map((row) => validHeaders.map((agCol) => row[agCol.colId]))];
    const validWorksheet = XLSX.utils.aoa_to_sheet(validWorksheetData);

    // Create worksheet for error data

    // Create a new workbook and append both sheets
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, validWorksheet, "Updated Price");
    if(hasErrors){
      const errorWorksheetData = [headers.map(agCol=>agCol.userProvidedColDef.headerName), ...rowsWithError.map((row) => headers.map((agCol) => row[agCol.colId]))];
      const errorWorksheet = XLSX.utils.aoa_to_sheet(errorWorksheetData);
      XLSX.utils.book_append_sheet(workbook, errorWorksheet, "Error Line Items");
    }

    // Generate Excel file and trigger download
    // XLSX.writeFile(workbook, "price_update.xlsx");
    const buffer = XLSX.write(workbook, { bookType: "xlsx", type: "buffer" });
    uploadFile({fileData: buffer, fileName: [fileName,"price_update.xlsx"].join("_"), signedUrlCallback:(path,name,publicUrl)=>{
      createBulkPriceUpdate({variables:{
        input: {
          path,
          publicUrl,
          filename: name,
          brand: brand.id,
        },
      },onCompleted:(data)=>{
        setNotification({
          color: "success",
          isVisible: true,
          message: "Bulk Price Update created succesfully",
        });
        if(callback) callback();
      }})
    }})
  }

  return (
    <Box
      sx={{
        background: "white",
        width: "90%",
        height: "75vh",
        margin: "auto",
        marginTop: 10,
        padding: 2,
        overflow:"auto",
        display: 'flex',
        flexDirection:"column",
      }}
    >
      <Typography variant="h5" gutterBottom>
        Bulk Price Update Preview
      </Typography>
      {/* <p>{`${hasErrors}`}</p> */}
      <div
        className="ag-theme-alpine"
        style={{ flex:1, width: "100%", marginTop: "20px" }}
      >
        <AgGridReact
          autoSizeStrategy={{
            type: "fitGridWidth",
            defaultMinWidth: 150,
          }}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={{ editable: true, singleClickEdit: true }}
          onGridReady={onGridReady}
          onCellValueChanged={onCellValueChanged} // Event handler for cell value changes
          onFirstDataRendered={onFirstDataRendered}
        />
      </div>

      <MDButton
        style={{ width:"fit-content", marginLeft:"auto" }}
        circular={true}
        variant={"contained"}
        color={"black"}
        onClick={handleImport}
      >
        {hasErrors?'Import Prices Without Errors':'Import All'}
      </MDButton>
      
    </Box>
  );
}
