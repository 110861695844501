export const PreviewBulkProductExcelColumnDefs = [
  { headerName: "Brand ID", field: "Brand ID" },
  { headerName: "Category Id", field: "Category Id" },
  { headerName: "Cname3", field: "Cname3" },
  { headerName: "Cname4", field: "Cname4" },
  { headerName: "Cname6", field: "Cname6" },
  { headerName: "Desc1", field: "Desc1" },
  { headerName: "Desc2", field: "Desc2" },
  { headerName: "Desc4", field: "Desc4" },
  { headerName: "Desc5", field: "Desc5" },
  { headerName: "Desc6", field: "Desc6" },
  { headerName: "Udf 2", field: "Udf 2" },
  { headerName: "Division", field: "Division" },
  { headerName: "Section", field: "Section" },
  { headerName: "Department", field: "Department" },
  { headerName: "Article Name", field: "Article Name" },
  { headerName: "Item Name", field: "Item Name" },
  { headerName: "Short Name", field: "Short Name" },
  { headerName: "Parent Article Name", field: "Parent Article Name" },
  { headerName: "Genysis Item Code", field: "Genysis Item Code" },
  { headerName: "Vendor Article Number", field: "Vendor Article Number" },
  { headerName: "Scan Unit", field: "Scan Unit", type: "numericColumn" },
  { headerName: "Sample Flag", field: "Sample Flag" },
  {
    headerName: "Minimum Selling Price",
    field: "Minimum Selling Price",
    type: "numericColumn",
  },
  {
    headerName: "Maximum Retail Price",
    field: "Maximum Retail Price",
    type: "numericColumn",
  },
  {
    headerName: "Listing Price",
    field: "Listing Price",
    type: "numericColumn",
  },
  { headerName: "HSN Code", field: "HSN Code" },
  { headerName: "Bar Code", field: "Bar Code", type: "numericColumn" },
  { headerName: "Tax Rate", field: "Tax Rate", type: "numericColumn" },
  { headerName: "Message", field: "message" },
  {
    headerName: "Validated",
    field: "validated",
    cellRenderer: (params) => {
      //const value = params.getValue(params.getvalue("validated"));
      const value = params.data.validated;
      return value ? "Yes" : "Validation Failed";
    },
  },
];
export const getRowStyle = (params) => {
  if (!params.data.message.toLowerCase().includes("validated")) {
    return { backgroundColor: "red", color: "white" };
  }
  return null;
};
