
/** 
  All of the routes for the Material Dashboard 2 React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 React layouts
import Dashboard from "layouts/dashboard";
import Brands from "layouts/brands";
import Events from "layouts/events";
import ECommerceMedia from "layouts/ecommerce-media";
import EcommerceSection from "layouts/ecommerce-section";
import Stores from "layouts/stores";
import News from "layouts/news";
import Product from "layouts/product";
import ProductCategories from "layouts/product-category";
import MasterAttribute from "layouts/master-attribute";
import Channel from "layouts/channel";
import Loyalty from "layouts/loyalty";
// @mui icons
import Icon from "@mui/material/Icon";

import Customer from "layouts/cutsomer";
import Theme from "layouts/theme/theme";
import Order from "layouts/order"
import Promotion from "layouts/promotion";
import MasterActivity from "layouts/activity-master";
import Activity from "layouts/activity";
import Advertisment from "layouts/advertisement";
import DeviceMaster from "layouts/device-Master";
import FixtureMaster from "layouts/fixture-Master";
import ListIcon from '@mui/icons-material/List';
import EcommerceComponent from "layouts/ecommerce-component";
import Reports from "layouts/reports"
import QRCode from "layouts/qr-code";

const adminRoutes = [
  {
    type: "collapse",
    name: "Brands",
    key: "dashboard",
    icon: <Icon fontSize="small">star</Icon>,
    route: "/dashboard",
    component: <Brands />,
  },
  {
    type: "collapse",
    name: "Stores",
    key: "stores",
    icon: <Icon fontSize="small">store_front</Icon>,
    route: "/stores",
    component: <Stores />,
  },
  {
    type: "collapse",
    name: "Events",
    key: "events",
    icon: <Icon fontSize="small">confirmation_number</Icon>,
    route: "/events",
    component: <Events />,
  },
  {
    type: "collapse",
    name: "Device Master",
    key: "device-master",
    icon: <Icon fontSize="small">monitor</Icon>,
    route: "/device-master",
    component: <DeviceMaster/>,
  },
  {
    type: "collapse",
    name: "Fixture Master",
    key: "fixture-master",
    icon: <Icon fontSize="small">monitor</Icon>,
    route: "/fixture-master",
    component: <FixtureMaster/>,
  },
  {
    type: "collapse",
    name: "Store Digital Content",
    key: "advertisment",
    icon: <Icon fontSize="small">ondemand_video</Icon>,
    route: "/advertisment",
    component: <Advertisment/>,
  },
  {
    type: "collapse",
    name: "Product",
    key: "product",
    icon: <Icon fontSize="small">inventory_2</Icon>,
    route: "/product",
    component: <Product />,
  },
  {
    type: "collapse",
    name: "Product Category",
    key: "product-category",
    icon: <Icon fontSize="small">category</Icon>,
    route: "/product-category",
    component: <ProductCategories />,
  },
  {
    type: "collapse",
    name: "Master Attribute",
    key: "master-attribute",
    icon: <Icon fontSize="small">summarize</Icon>,
    route: "/master-attribute",
    component: <MasterAttribute />,
  },
  {
    type: "collapse",
    name: "Customer",
    key: "customer",
    icon: <Icon fontSize="small">group</Icon>,
    route: "/customer",
    component: <Customer />,
  },
  {
    type: "collapse",
    name: "Orders",
    key: "orders",
    icon: <Icon fontSize="small">shopping_cart</Icon>,
    route: "/orders",
    component: <Order />,
  },
  {
    type: "collapse",
    name: "Loyalty",
    key: "loyalty",
    icon: <Icon fontSize="small">emoji_events</Icon>,
    route: "/loyalty",
    component: <Loyalty />,
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    icon: <Icon fontSize="small">analytics</Icon>,
    route: "/reports",
    component: <Reports />,
  },
  // {
  //   type: "collapse",
  //   name: "QR-Code",
  //   key: "qr-code",
  //   icon: <Icon fontSize="small">qr_code_scanner</Icon>,
  //   route: "/qr-code",
  //   component: <QRCode />,
  // },
  // {
  //   type: "collapse",
  //   name: "Promotion",
  //   key: "promotion",
  //   icon: <Icon fontSize="small">percent</Icon>,
  //   route: "/promotion",
  //   component: <Promotion />,
  // },
  // {
  //   type: "collapse",
  //   name: "Master Activity",
  //   key: "master-activity",
  //   icon: <Icon fontSize="small">folder_special</Icon>,
  //   route: "/master-activity",
  //   component: <MasterActivity />,
  // },
  // {
  //   type: "collapse",
  //   name: "Activity",
  //   key: "activity",
  //   icon: <Icon fontSize="small">star</Icon>,
  //   route: "/activity",
  //   component: <Activity/>,
  // },
  // {
  //   type: "collapse",
  //   name: "Channel",
  //   key: "channel",
  //   icon: <Icon fontSize="small">account_tree</Icon>,
  //   route: "/channel",
  //   component: <Channel />,
  // },
  // {
  //   type: "collapse",
  //   name: "E-Commerce Media",
  //   key: "ecommerce-media",
  //   icon: <Icon fontSize="small">video_camera_back</Icon>,
  //   route: "/ecommerce-media",
  //   component: <ECommerceMedia/>,
  // },
  // {
  //   type: "collapse",
  //   name: "E-Commerce Section",
  //   key: "ecommerce-section",
  //   icon: <Icon fontSize="small"><ListIcon /></Icon>,
  //   route: "/ecommerce-section",
  //   component: <EcommerceSection/>,
  // },
  // {
  //   type: "collapse",
  //   name: "E-Commerce Comopoent",
  //   key: "ecommerce-component",
  //   icon: <Icon fontSize="small"><ListIcon /></Icon>,
  //   route: "/ecommerce-component",
  //   component: <EcommerceComponent />,
  // },
  // {
  //   type: "collapse",
  //   name: "Categories",
  //   key: "categories",
  //   icon: <GridViewRoundedIcon fontSize="small" />,
  //   route: "/categories",
  //   component: <Categories />,
  // },
  // {
  //   type: "collapse",
  //   name: "News",
  //   key: "news",
  //   icon: <Icon fontSize="small">newspaper</Icon>,
  //   route: "/news",
  //   component: <News />,
  // },
  // {
  //   type: "collapse",
  //   name: "Theme",
  //   key: "theme",
  //   icon: <Icon fontSize="small">color_lense</Icon>,
  //   route: "/theme",
  //   component: <Theme />,
  // },
  // {
  //   type: "collapse",
  //   name: "Communication",
  //   key: "communication",
  //   icon: <Icon fontSize="small">chat</Icon>,
  //   route: "/communication",
  //   component: <Dashboard />,
  // },
  // {
  //   type: "collapse",
  //   name: "Accounting",
  //   key: "accounting",
  //   icon: <Icon fontSize="small">receipt_long</Icon>,
  //   route: "/accounting",
  //   component: <Dashboard />,
  // },
];

const routes = [
  {
    type: "collapse",
    name: "Brands",
    key: "dashboard",
    icon: <Icon fontSize="small">star</Icon>,
    route: "/dashboard",
    component: <Brands />,
  },
  
  {
    type: "collapse",
    name: "Product",
    key: "product",
    icon: <Icon fontSize="small">inventory_2</Icon>,
    route: "/product",
    component: <Product />,
  },
  {
    type: "collapse",
    name: "Events",
    key: "events",
    icon: <Icon fontSize="small">confirmation_number</Icon>,
    route: "/events",
    component: <Events />,
  },
  {
    type: "collapse",
    name: "Store Digital Content",
    key: "advertisment",
    icon: <Icon fontSize="small">ondemand_video</Icon>,
    route: "/advertisment",
    component: <Advertisment/>,
  },
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    icon: <Icon fontSize="small">analytics</Icon>,
    route: "/reports",
    component: <Reports />,
  }
];

const broadwayRm = [
  {
    type: "collapse",
    name: "Reports",
    key: "reports",
    icon: <Icon fontSize="small">analytics</Icon>,
    route: "/reports",
    component: <Reports />,
  },
]

export { adminRoutes, routes, broadwayRm };
