import React, { useState } from "react";
import OrderLandingPage from "./orderLandingPage";
// import CustomerTabs from "./customerTabs";
import { Grid, Box, Icon, Button, Drawer, Typography, IconButton, Autocomplete, TextField } from "@mui/material";
import DashboardLayout from "ui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "ui/Navbars/DashboardNavbar";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ReactComponent as OrderIcon } from "assets/images/orderIcon.svg";
import { Add as AddIcon } from "@mui/icons-material";
import OrderTabs from "./orderTabs"
import OrderDetailLandingPage from "./orderDetailsListing";
import { BRANDS_LIST } from "services/brand-service";
import { dashboardServiceClient } from "graphql/client";
import { useQuery } from "@apollo/client";

function Order() {
  const [isOrderDetail, setIsOrderDetail] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const user = localStorage.getItem("userDetails") && localStorage.getItem("userDetails") !== "undefined" ? JSON.parse(localStorage.getItem("userDetails")) : {};

  const handleRowClick = (params) => {
    setSelectedOrder(params.data?.id);
    setIsOrderDetail(true)
  };

  const { loading, data, error, refetch } = useQuery(BRANDS_LIST, {
    client: dashboardServiceClient,
    variables: {
        take:100,
        skip: 0,
        filter:
            user && user?.role === "admin"
                ? {
                    isDeleted: false,
                }
                : {
                    id: user?.userId,
                },
    },
});

  const OrderDrawer = ({ open, handleClose, selectedOrder }) => (
    <Drawer
      anchor="right"
      open={open}
      onClose={handleClose}
      PaperProps={{ sx: { width: "50%" } }}
    >
      <Grid
        container
        sx={{ backgroundColor: "#F0F2F5", pb: 3, pt: 2, pl: "5px" }}
      >
        <Grid item xs={12}>
          &nbsp;
        </Grid>
        <Grid item xs={12}>
          <Icon
            sx={{ mr: 2, ml: 2 }}
            component={OrderIcon}
            fontSize="large"
          />
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h5" sx={{ ml: 2 }}>
            Order
          </Typography>
        </Grid>
      </Grid>
      <Box sx={{ width: "100%", minHeight: "70vh" }}>
        <OrderTabs
          orderData={selectedOrder}
          handleClose={handleClose}
        />
      </Box>
    </Drawer>
  );
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [selectedBrand, setSelectedBrand] = useState(null);

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}
      <Grid item xs={12} sm={6} md={3}>
        {user && user?.role === "admin" && <Grid item xs={2}>
          <Autocomplete
            labelId="demo-simple-select-label"
            label="Select Brand"
            id="demo-simple-select"
            name="brand"
            loading={loading}
            iconComponent={() => (
              <ArrowDropDownIcon style={{ marginRight: "18px" }} />
            )}
            options={data?.brandlist?.results
              ?.map((item) => (
                { label: item?.name, value: item?.id }
              )) || []}
            onChange={(e, newValue) => {
              setSelectedBrand(newValue?.value || null);
            }}

            sx={{ "& .MuiInputBase-root": { height: 40, borderRadius: "7px", px: 2, backgroundColor: "white.main", width:"300px" } }}
            renderInput={(params) => <TextField {...params} label="Select Brand" />}
          />
        </Grid>}
        {!isOrderDetail ? (<>
          <OrderLandingPage onRowClick={handleRowClick} selectedBrand={selectedBrand} /></>) : <div>
          <Grid mt={2}>
            <IconButton
              aria-label="Go Back"
              onClick={() => { setIsOrderDetail(false); }}
            >
              <ArrowBackIcon />
              <Typography
                sx={{ fontSize: "16px", ml: 1, fontWeight: "bold" }}
              >
                Back
              </Typography>
            </IconButton>
          </Grid>
          <OrderDetailLandingPage selectedOrder={selectedOrder}/>
         </div>}
      </Grid>
    </DashboardLayout>
  );
}

export default Order;
