import * as React from "react";
import {
  Box,
  IconButton,
  Icon,
  Chip,
  Select,
  MenuItem,
  FormControl,
} from "@mui/material";
import { ReactComponent as previewIcon } from "assets/images/previewIcon.svg";
import { ReactComponent as commentIcon } from "assets/images/commentIcon.svg";
import { ReactComponent as downloadIcon } from "assets/images/downloadIcon.svg";
import { ReactComponent as deleteIcon } from "assets/images/deleteIcon.svg";
import { LIST_ALL_IMAGE_MASTERS } from "services/advertisment-service";
import { vmServiceClient } from "graphql/client";
import { useQuery, useMutation } from "@apollo/client";
import Loader from "components/MDLoader";
import { CREATE_IMAGE_MASTER } from "services/advertisment-service";
import { UPDATE_IMAGE_MASTER } from "services/advertisment-service";
import { REMOVE_IMAGE_MASTER } from "services/advertisment-service";
import { SearchContext } from "context";
import { useContext } from "react";
import MDButton from "components/MDButton";
import { KeyboardArrowDown, UploadSharp } from "@mui/icons-material";
import AdsTable from "../adsTable";
import ImageUploaderContainer from "./uploadImage";
import { DropDownSelectionContext } from "..";

export const NewImageButton = ({
  resetUploader,
  setResetUploader,
  isSubmitting,
  setIsSubmitting,
  selectedBrandId,
  user,
  callback = null,
  keyPrefix = "",
}) => {
  const { searchKeyword, setSearchKeyword } = useContext(SearchContext);

  const [createImageMutation] = useMutation(CREATE_IMAGE_MASTER, {
    client: vmServiceClient,
    refetchQueries: [
      {
        query: LIST_ALL_IMAGE_MASTERS,
        variables: {
          page: 1,
          limit: 100,
          sortBy: "updatedAt",
          order: "DESC",
          relations: [],
          searchCols: ["brandId", "name"],
          searchBy:
            user && user?.role !== "admin"
              ? [selectedBrandId || "_nobrand", searchKeyword]
              : [selectedBrandId || "", searchKeyword],
        },
      },
    ],
  });
  const handleSubmit = ({ path, name, publicurl, imageMetaData }) => {
    if (isSubmitting) return; // Prevent multiple submissions
    setIsSubmitting(true);

    const payload = {
      brandId: selectedBrandId,
      name: name,
      dimension: String(imageMetaData?.dimension), // Ensure this is a string. Example: "00:08:19"
      format: imageMetaData.format.toUpperCase(), // Ensure correct enum value
      size: String(imageMetaData.size), // Convert to string if it's numeric
      path: path,
      publicUrl: publicurl,
      status: "PENDING",
    };
    createImageMutation({
      variables: { input: payload },
      onCompleted: (data) => {
        setIsSubmitting(false);
        setResetUploader(true); // Set to true to reset the uploader
        setTimeout(() => setResetUploader(false), 0); // Optional: reset the state immediately
        if (callback) callback(data?.createImageMaster);
        refetch({
          variables: {
            page: 1,
            limit: 100,
            sortBy: "updatedAt",
            order: "DESC",
            relations: [],
            searchCols: ["brandId"],
            searchBy: user && user?.role !== "admin" ? [selectedBrandId] : [],
          },
        });
      },
      onError: () => setIsSubmitting(false),
    });
  };

  return (
    <ImageUploaderContainer
      sx={{ height: "2rem" }}
      preLoadedFile={[]}
      isMultiple={false}
      keyPrefix={keyPrefix}
      reset={resetUploader}
      signedUrlCallback={(path, name, publicurl, imageMetaData) => {
        handleSubmit({ path, name, publicurl, imageMetaData }); // need to submit an actual payload
        // onSubmit({ path, name, publicurl, videoMetadata });
      }}
      fieldName={"Upload Image"}
      isFieldValid={true}
      isVideo={false}
      uploadButton={
        <MDButton
          type="button"
          variant="contained"
          color="dark"
          circular={true}
          sx={{ borderRadius: "100px", mr: 1, width: "max-content" }}
        >
          <UploadSharp /> Upload New Image
        </MDButton>
      }
    />
  );
};
export default function ImagesPage() {
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [resetUploader, setResetUploader] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);

  const { selectedBrandId, user, Brand } = useContext(DropDownSelectionContext);
  const { searchKeyword, setSearchKeyword } = useContext(SearchContext);

  const columns = [
    { field: "uuid", headerName: "ID", width: 130, flex: 1 },
    {
      field: "brandId",
      headerName: "Brand",
      width: 130,
      flex: 1,
      renderCell: (params) => {
        return <span>{Brand?.name || "Uknown Brand"}</span>;
      },
    },
    { field: "name", headerName: "Name", width: 130, flex: 2 },
    { field: "dimension", headerName: "Dimension", width: 130, flex: 1 },
    { field: "format", headerName: "Format", width: 130, flex: 1 },
    { field: "size", headerName: "Size", width: 130, flex: 1 },
    {
      field: "status",
      headerName: "Status",
      width: 200,
      flex: 2,
      renderCell: (params) => {
        if (user && user?.role !== "admin") {
          return (
            <Chip
              label={params.value}
              color={
                params.value === "APPROVED"
                  ? "success"
                  : params.value === "PENDING"
                  ? "warning"
                  : "error"
              }
              size="small"
            />
          );
        }

        return (
          <FormControl fullWidth>
            <Select
              onChange={(e) =>
                handleStatusChange(params.row.uuid, e.target.value)
              }
              defaultValue={params.value}
              style={{
                width: "100%",
                padding: 10,
                borderRadius: 6,
                height: 32,
              }}
              id="status-select"
              IconComponent={() => (
                <KeyboardArrowDown
                  sx={{ fontSize: 24, width: 24, height: 24 }}
                />
              )}
            >
              <MenuItem value="APPROVED">APPROVED</MenuItem>
              <MenuItem value="PENDING">PENDING</MenuItem>
              <MenuItem value="REJECTED">REJECTED</MenuItem>
            </Select>
          </FormControl>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      disableColumnMenu: true,
      headerAlign: "left",
      width: 240,
      flex: 2,
      renderCell: (params) => (
        <Box sx={{ "& button": { mr: 1 } }}>
          <IconButton
            aria-label="edit"
            onClick={() => handleEditClick(params.row.publicUrl)}
          >
            <Icon component={previewIcon} fontSize="small" />
          </IconButton>
          <IconButton
            aria-label="save"
            disabled
            sx={{ opacity: 0.25 }}
            onClick={() => alert("Clicked")}
          >
            <Icon component={commentIcon} fontSize="small" />
          </IconButton>
          <IconButton
            aria-label="download"
            onClick={() =>
              handleDownloadClick(params.row.publicUrl, params.row.name)
            }
          >
            <Icon component={downloadIcon} fontSize="small" />
          </IconButton>
          <IconButton
            aria-label="delete"
            onClick={() => handleDeleteClick(params.row.uuid)}
          >
            <Icon component={deleteIcon} fontSize="small" />
          </IconButton>
        </Box>
      ),
    },
  ];

  const [removeImageMutation] = useMutation(REMOVE_IMAGE_MASTER, {
    client: vmServiceClient,
    refetchQueries: [
      {
        query: LIST_ALL_IMAGE_MASTERS,
        variables: {
          page: 1,
          limit: 10,
          sortBy: "updatedAt",
          order: "DESC",
          relations: [],
          searchCols: ["brandId"],
          searchBy: user && user?.role !== "admin" ? [selectedBrandId] : [],
        },
      },
    ],
  });
  const [updateImageMutation] = useMutation(UPDATE_IMAGE_MASTER, {
    client: vmServiceClient,
    refetchQueries: [
      {
        query: LIST_ALL_IMAGE_MASTERS,
        variables: {
          page: 1,
          limit: 10,
          sortBy: "updatedAt",
          order: "DESC",
          relations: [],
          searchCols: ["brandId"],
          searchBy: user && user?.role !== "admin" ? [selectedBrandId] : [],
        },
      },
    ],
  });

  const handleStatusChange = (id, status) => {
    updateImageMutation({
      variables: { input: { uuid: id, status: status } },
    });
  };

  const handleDeleteClick = (imageId) => {
    setIsLoading(true);
    // setIsLoading(true);
    removeImageMutation({
      variables: { id: imageId },
      onCompleted: () => {
        setIsLoading(false);
        // setIsLoading(false);
      },
      onError: (error) => {
        setIsLoading(false);
      },
    });
  };

  const handleEditClick = (imageUrl) => {
    const newTab = window.open("", "_blank");
    newTab.document.body.innerHTML = `
    <img src="${imageUrl}" />
  `;
    newTab.focus();
  };

  const handleDownloadClick = (imageUrl, imageName) => {
    // This creates a new anchor element and triggers a download
    const link = document.createElement("a");
    link.href = imageUrl;
    link.download = imageName; // You can set the default file name for the download here
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const { loading, error, data, refetch } = useQuery(LIST_ALL_IMAGE_MASTERS, {
    client: vmServiceClient,
    fetchPolicy: "network-only",
    variables: {
      page: page + 1,
      limit: rowsPerPage,
      sortBy: "updatedAt",
      order: "DESC",
      relations: [],
      searchCols: ["brandId", "name"],
      searchBy:
        user && user?.role !== "admin"
          ? [selectedBrandId || "_nobrand", searchKeyword]
          : [selectedBrandId || "", searchKeyword],
    },
  });

  // Define a function that can handle submission

  // React.useEffect(() => {
  //   refetch();
  // }, [user, selectedBrandId, handleDeleteClick]);

  // React.useEffect(() => {
  //   refetch({
  //     variables: {
  //       "page": 1,
  //       "limit": 100,
  //       "sortBy": "updatedAt",
  //       "order": "DESC",
  //       "relations": [],
  //       "searchCols": ["brandId"],
  //       "searchBy": user && user?.role !== "admin" ? [selectedBrandId] : []
  //     },
  //   });;
  // }, [user, selectedBrandId, handleDeleteClick, searchKeyword])

  if (loading || isLoading) return <Loader />;
  if (error) return <p>Error: {error?.message}</p>;

  function filterObjectsByField(objects, field, searchTerm) {
    return objects.filter((obj) => {
      // Convert the field value to lowercase for case-insensitive search
      const fieldValue = obj[field].toLowerCase();
      // Convert the search term to lowercase as well
      const search = searchTerm.toLowerCase();
      // Check if the field contains the search term
      return fieldValue.includes(search);
    });
  }
  const handlePaginationRefetch = ({ page, rowsPerPage }) => {
    refetch({
      page: page,
      limit: rowsPerPage,
      sortBy: "updatedAt",
      order: "DESC",
      relations: [],
      searchCols: ["brandId"],
      searchBy: user && user?.role !== "admin" ? [selectedBrandId] : [],
    });
  };

  const imageRows =
    searchKeyword?.length <= 0
      ? data?.findAllImageMasters?.results
      : filterObjectsByField(
          data?.findAllImageMasters.results,
          "name",
          searchKeyword
        );
  const totalRows = data?.findAllImageMasters?.total;
  return (
    <Box
      sx={{
        width: "100%",
        height: "70vh",

        "& .MuiDataGrid-row": { borderBottom: "1px solid #e0e0e0" },
      }}
    >
      <AdsTable
        rows={imageRows}
        callback={handlePaginationRefetch}
        totalRows={totalRows}
        columns={columns}
        footerCTA={
          !!selectedBrandId && (
            <Box>
              <NewImageButton
                selectedBrandId={selectedBrandId}
                resetUploader={resetUploader}
                setResetUploader={setResetUploader}
                setIsSubmitting={setIsSubmitting}
                isSubmitting={isSubmitting}
                user={user}
              />
            </Box>
          )
        }
        page={page}
        setPage={setPage}
        rowsPerPage={rowsPerPage}
        setRowsPerPage={setRowsPerPage}
      />
    </Box>
  );
}
