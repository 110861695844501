import React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

import CustomerTab from "./customer";
import ActivitiesTab from "./activities";

import MDBox from "components/MDBox";
import MDTabPanel from "components/MDTabPanel";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function LoyaltyTabs({
  tabValue,
  setTabValue,
}) {
  const handleChange = (_event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <MDBox sx={{}}>
      <MDBox sx={{}}>
        <Tabs value={tabValue} onChange={handleChange} aria-label="ant example">
          <Tab label="Activities" {...a11yProps(0)} />
          <Tab label="Customer" {...a11yProps(1)} />
        </Tabs>
      </MDBox>
      <MDTabPanel value={tabValue} index={0}>
        <ActivitiesTab />
      </MDTabPanel>
      <MDTabPanel value={tabValue} index={1}>
        <MDBox sx={{ width: "100%", minHeight: "auto", padding: "0px" }}>
          <CustomerTab />
        </MDBox>
      </MDTabPanel>
    </MDBox>
  );
}
