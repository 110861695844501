import { Button, Grid, Typography } from "@mui/material";

// import addIcon from "../../assets/images/add-icon.svg";
import AddIcon from "@mui/icons-material/Add";
import { AvailableForms, AvailableTabs } from "./productTable/tableData";
import MDButton from "components/MDButton";
import IosShareIcon from "@mui/icons-material/IosShare";
import axios from "axios";
import { useState } from "react";

export default function ProductNavbar({ setForm, selectedTab }) {
  const [downloadingFile, setDownloadingFile] = useState(false);

  const url =
    process.env.REACT_APP_NODE_ENV === "development"
      ? `${process.env.REACT_APP_API_BASE_URL}/product-service`
      : `/product-service`;

  const getActiveTabExportURL = () => {
    switch (selectedTab) {
      case AvailableTabs.singleProduct:
        return `${url}/variants/export?filter={JSON.stringify({limit: 1000, skip: 0})}`;
      // case AvailableTabs.nodeWiseBulkProduct:
      //   return `${url}/bulk-product/export`;
      case AvailableTabs.bulkProduct:
        return `${url}/super-admin-bulk-product/export?filter={JSON.stringify({limit: 1000, skip: 0})}`;
      default:
        return "/variants/export?filter={JSON.stringify({limit: 1000, skip: 0})}";
    }
  };

  const navbarButtons = [
    // {
    //   key: "node-wise-bulk-product",
    //   lable: "Node Wise Bulk Product Upload",
    //   onClick: () => {
    //     setForm(AvailableForms.nodeWiseBulkProduct);
    //   },
    // },
    {
      key: "mass-bulk-product",
      lable: "Mass Bulk Product Upload",
      onClick: () => {
        setForm(AvailableForms.bulkProduct);
      },
    },
    // {
    //   key: "bulk-price",
    //   lable: "Bulk Price Update",
    //   onClick: () => {
    //     setForm(AvailableForms.bulkPriceUpload);
    //   },
    // },
    {
      key: "export",
      lable: "Export Table Rows",
      onClick: () => {
        gridRef.current?.api.exportDataAsCsv();
      },
      startIcon: <IosShareIcon />,
      hoverIcon: <IosShareIcon />,
    },
  ];

  return (
    <Grid container justifyContent={"space-between"} gap={1}>
      <Grid item xs={12} sm={6} md={6}>
        <Typography
          sx={{ fontFamily: "montserrat", color: "#000", fontWeight: "700" }}
          ml={2}
        >
          Products
        </Typography>
      </Grid>
      <Grid>
        {navbarButtons.map((btn) => {
          return (
            <MDButton
              style={{ marginLeft: "8px" }}
              circular={true}
              variant={"contained"}
              color={"black"}
              {...btn}
              startIcon={btn.startIcon}
            >
              {btn.lable}
            </MDButton>
          );
        })}

        {/* <Button
          startIcon={<IosShareIcon />}
          onClick={async () => {
            const fileDownloadUrl = getActiveTabExportURL();

            setDownloadingFile(true);

            try {
              const response = await axios({
                url: fileDownloadUrl,
                method: "GET",
                responseType: "blob", // important
                headers: {
                  Authorization: `Bearer ${localStorage.getItem("token")}`,
                },
              });

              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement("a");
              link.href = url;
              link.setAttribute("download", "exported_data.csv"); // or any other extension
              document.body.appendChild(link);
              link.click();
              link.remove();
            } catch (e) {
            } finally {
              setDownloadingFile(false);
            }
          }}
          disabled={downloadingFile}
          style={{
            borderRadius: "10rem",
            backgroundColor: "black",
            color: "white",
            marginLeft: "8px",
          }}
          variant="contained"
        >
          {downloadingFile ? "Downloading..." : "Export"}
        </Button> */}
      </Grid>
    </Grid>
  );
}
