import React, { createContext, useContext, useEffect, useState } from "react";

// @mui material components
import { Grid, Autocomplete, TextField } from "@mui/material";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";

// Custom Components
import DashboardLayout from "ui/LayoutContainers/DashboardLayout";
import AdsTabs from "./adsTabs";

// Custom Context Creation
import { AuthContext } from "context";
import { useNotification } from "context";
export const DropDownSelectionContext = createContext();

// Custom Hooks
import useDebounce from "layouts/ecommerce-media/common/debounce";

// Apollo Client
import { useLazyQuery } from "@apollo/client";
import { dashboardServiceClient } from "graphql/client";

// Graphql queries & mutation
import { GET_BRANDS_LIST } from "services/brand-service";
import { MAP_BRAND_STORE } from "services/brand-service";
import { GET_STORES_LIST_ADS } from "services/store-service";

// Custom Component Creation
const BrandDropDown = ({
  brandList,
  setSearchBrandValues,
  loading,
  values,
  setSelectedBrand,
}) => (
  <Grid item xs={12}>
    <Autocomplete
      id="tags-standard"
      name="brand"
      value={
        values
          ? {
              id: values?.id,
              label: values?.name,
              brand: values,
            }
          : null
      }
      options={
        brandList?.map((item) => ({
          id: item.id,
          label: `${item?.name}`,
          brand: item,
        })) || []
      }
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id;
      }}
      onChange={(_event, newValue) => {
        setSelectedBrand(newValue?.brand || null);
      }}
      onInputChange={(event, newValue) => {
        if ((event && event?.type === "reset") || newValue === "") {
          setSearchBrandValues(""); // Clear search when input is cleared
        } else if (event && event.type === "change") {
          setSearchBrandValues(newValue);
        }
      }}
      onBlur={() => {
        setSearchBrandValues("");
      }}
      loading={loading}
      loadingText="Loading..."
      renderInput={(params) => <TextField {...params} label="Select Brand" />}
    />
  </Grid>
);

const StoreDropDown = ({
  storeList,
  setSearchStoreValues,
  loading,
  values,
  setSelectedStore,
}) => (
  <Grid item xs={12}>
    <Autocomplete
      id="tags-standard"
      name="store"
      value={
        values
          ? {
              id: values?.id,
              label: values?.name,
              store: values,
            }
          : null
      }
      options={
        storeList?.map((item) => ({
          id: item.id,
          label: `${item.name}`,
          store: item,
        })) || []
      }
      isOptionEqualToValue={(option, value) => {
        return option.id === value.id;
      }}
      onChange={(_event, newValue) => {
        setSelectedStore(newValue?.store || null);
      }}
      onInputChange={(event, newValue) => {
        if ((event && event?.type === "reset") || newValue === "") {
          setSearchStoreValues(""); // Clear search when input is cleared
        } else if (event && event.type === "change") {
          setSearchStoreValues(newValue);
        }
      }}
      onBlur={() => {
        setSearchStoreValues("");
      }}
      loading={loading}
      loadingText="Loading..."
      renderInput={(params) => <TextField {...params} label="Select Store" />}
    />
  </Grid>
);

function Advertisment() {
  const { user } = useContext(AuthContext);
  const { setNotification } = useNotification();

  const [selectedBrand, setSelectedBrand] = useState(null);
  const [selectedStore, setSelectedStore] = useState(null);

  // * to help with Brand Selections
  const [searachBrandValues, setSearchBrandValues] = useState("");
  const debouncedBrandSearchTerm = useDebounce(searachBrandValues, 300);
  const [
    getBrandList,
    { loading: brandLoading, data: brandData, error: brandError },
  ] = useLazyQuery(GET_BRANDS_LIST, {
    client: dashboardServiceClient,
    variables: {
      take: 5,
      skip: 0,
      search: debouncedBrandSearchTerm,
      filter: {},
    },
    fetchPolicy: "network-only",
    onError: (error) => {
      console.error("Error: fetching brands", error);
      setNotification({
        color: "error",
        isVisible: true,
        message: error.message || "Error: fetching brands",
      });
    },
  });

  // * to help with Brand Store Selections
  const [searachStoreValues, setSearchStoreValues] = useState("");
  const debouncedStoreSearchTerm = useDebounce(searachStoreValues, 300);
  const [
    getBrandStoreList,
    {
      loading: brandStoreLoading,
      data: brandStoreData,
      error: brandStoreError,
    },
  ] = useLazyQuery(MAP_BRAND_STORE, {
    client: dashboardServiceClient,
    variables: {
      listBrandStoreFilter: {
        take: 5,
        skip: 0,
        search: debouncedStoreSearchTerm,
        filter: {
          brand: { id: selectedBrand?.id },
          store: { id: selectedStore?.id },
          isDeleted: false,
        },
      },
    },
    fetchPolicy: "network-only",
    onError: (error) => {
      console.error("Error: fetching brands store", error);
      setNotification({
        color: "error",
        isVisible: true,
        message: error.message || "Error: fetching brands store",
      });
    },
  });

  // * direct store list if user selects that first
  const [
    getStoreList,
    { loading: storeListLoading, data: storelistData, error: storeListError },
  ] = useLazyQuery(GET_STORES_LIST_ADS, {
    client: dashboardServiceClient,
    variables: {
      listStoreFilter: {
        take: 5,
        skip: 0,
        search: debouncedStoreSearchTerm,
        filter: {
          status: 'active',
        },
      },
    },
    fetchPolicy: "network-only",
    onError: (error) => {
      console.error("Error: fetching store list", error);
      setNotification({
        color: "error",
        isVisible: true,
        message: error.message || "Error: fetching store list",
      });
    },
  });

  useEffect(() => {
    if (user && ["admin"].includes(user.role)) {
      getBrandList();
    } else {
      setSelectedBrand({...user, id: user?.userId }); // * so that in brand login we have selectedBrand.id
    }
  }, [user]);

  useEffect(() => {
    if (selectedBrand) {
      getBrandStoreList();
    } else {
      getStoreList()
    }
  }, [selectedBrand]);

  useEffect(() => {
    if(selectedStore) {
      getBrandStoreList();
    } else {
      if (user && ["admin"].includes(user.role)) {
        getBrandList();
      } else {
        getBrandStoreList();
      }
    }
  }, [selectedStore])

  const getStoresList = () => {
    if (selectedBrand) {
      return brandStoreData?.brandStores?.results?.map((item) => ({
        row: item,
        id: item.store.id,
        name: item.store.name,
        "__typename": 'brandStores' // * this to help us where this data coming from
      })) || [];
    } else {
      return storelistData?.stores?.results?.map((item) => ({
        row: item,
        id: item.id,
        name: item.name,
        "__typename": 'stores' // * this to help us where this data coming from
      })) || [];
    }
  }

  const getBrandsList = () => {
    if (selectedStore) {
      return [
        ...new Map(
          brandStoreData?.brandStores?.results?.map((item) => [
            item.brand.id, // Using brand id as the unique key
            {
              row: item,
              id: item.brand.id,
              name: item.brand.name,
              "__typename": 'brandStores', // Added type name to identify the data source
            },
          ])
        ).values(), // to get unique stores
      ] || [];
    } else {
      return [
        ...new Map(
          brandData?.brandlist?.results?.map((item) => [
            item.id, // Use the brand id as the unique key
            {
              row: item,
              id: item.id,
              name: item.name,
              "__typename": 'brandlist', // To identify the source of the data
            },
          ])
        ).values(), // to get unique brands
      ] || [];
    }
  }

  const getBrand = () => {
    if(user.role === 'admin') {
      return selectedBrand?.['__typename'] === 'brandStores' ? selectedBrand?.row?.brand || {} : selectedBrand?.row || {}
    } else {
      return selectedBrand;
    }
  }

  const getStore = () => {
    if(user.role === 'admin') {
      return selectedStore?.['__typename'] === 'brandStores' ? selectedStore?.row?.store || {} : selectedStore?.row || {};
    } else {
      return selectedStore;
    }
  }

  return (
    <DashboardLayout>
      <Grid container mt={2}>
        <Grid item xs={12} display="flex">
          {user && user.role == "admin" && (
            <BrandDropDown
              key={`${selectedStore?.id || 1}`}
              brandList={getBrandsList()}
              loading={selectedStore ? brandStoreLoading : brandLoading}
              name="brand"
              setSearchBrandValues={setSearchBrandValues}
              values={selectedBrand}
              setSelectedBrand={setSelectedBrand}
            />
          )}
          <StoreDropDown
            key={`${selectedBrand?.id || 2}`}
            storeList={getStoresList()}
            loading={selectedBrand ? brandStoreLoading : storeListLoading}
            name="store"
            setSearchStoreValues={setSearchStoreValues}
            values={selectedStore}
            setSelectedStore={setSelectedStore}
          />
        </Grid>
        <MDBox
          sx={{
            width: "100%",
            minHeight: "auto",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            mt: 4,
          }}
        >
          <DropDownSelectionContext.Provider value={{
            selectedBrandId: selectedBrand?.id,
            selectedStoreId: selectedStore?.id,
            Brand: getBrand() ,
            Store: getStore(),
            user,
          }}>
            <AdsTabs selectedStore={selectedStore} selectedBrand={selectedBrand} />
          </DropDownSelectionContext.Provider>
        </MDBox>
      </Grid>
    </DashboardLayout>
  );
}

export default Advertisment;
