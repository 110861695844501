import React from "react";

// @mui material components
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Custom Component
import VideoPage from "./videoMaster";
import ImagesPage from "./imageMaster";
import PlaylistsPage from "./playlistMaster";
import AssignedDevicesPage from "./assignedDevicesMaster";
import FixturesPage from "./assignedFixturesMaster";

// Material Dashboard 2 React components
import MDTabPanel from "components/MDTabPanel";
import MDTypography from "components/MDTypography";
import MDBox from "components/MDBox";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function AdsTabs({ selectedStore, selectedBrand }) {
  const [tabValue, setTabValue] = React.useState(0);
  const handleChange = (_event, newValue) => {
    setTabValue(newValue);
  };

  return selectedStore && selectedBrand ? (
    <>
      <Tabs
        sx={{ px: 2 }}
        value={tabValue}
        onChange={handleChange}
        aria-label="ant example"
      >
        <Tab
          sx={{ width: "150px", mr: 1, fontWeight: 300, color: "black.main" }}
          label="Assigned Devices"
          {...a11yProps(0)}
        />
        <Tab
          sx={{ width: "150px", mr: 1, fontWeight: 300, color: "black.main" }}
          label="Assigned Fixtures"
          {...a11yProps(1)}
        />
        <Tab
          sx={{ width: "150px", mr: 1, fontWeight: 300, color: "black.main" }}
          label="Playlists"
          {...a11yProps(2)}
        />
        <Tab
          sx={{ width: "150px", mr: 1, fontWeight: 300, color: "black.main" }}
          label="Videos"
          {...a11yProps(3)}
        />
        <Tab
          sx={{ width: "150px", mr: 1, fontWeight: 300, color: "black.main" }}
          label="Images"
          {...a11yProps(4)}
        />
      </Tabs>
      <MDTabPanel value={tabValue} index={0}>
        <MDBox sx={{ p: 2 }}>
          <AssignedDevicesPage />
        </MDBox>
      </MDTabPanel>
      <MDTabPanel value={tabValue} index={1}>
        <MDBox sx={{ p: 2 }}>
          <FixturesPage tabValue={tabValue} />
        </MDBox>
      </MDTabPanel>
      <MDTabPanel value={tabValue} index={2}>
        <MDBox sx={{ p: 2 }}>
          <PlaylistsPage tabValue={tabValue} />
        </MDBox>
      </MDTabPanel>
      <MDTabPanel value={tabValue} index={3}>
        <MDBox sx={{ p: 2 }}>
          <VideoPage tabValue={tabValue} />
        </MDBox>
      </MDTabPanel>
      <MDTabPanel value={tabValue} index={4}>
        <MDBox sx={{ p: 2 }}>
          <ImagesPage />
        </MDBox>
      </MDTabPanel>
    </>
  ) : (
    <MDBox>
      <MDTypography
        variant="h5"
        fontWeight="medium"
        color="dark"
        sx={{ padding: "24px" }}
      >
        Please select store as well as brand to see mapped advertisments art
        work and assets
      </MDTypography>
    </MDBox>
  );
}
