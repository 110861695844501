import React, { useContext, useState } from 'react'

// Custom Context
import { AuthContext, useNotification } from 'context'

// Custom Components
import DashboardLayout from 'ui/LayoutContainers/DashboardLayout';
import MDBox from 'components/MDBox';
import MDTypography from 'components/MDTypography';
import MDButton from '../../components/MDButton';

// @mui material components
import { Grid, IconButton, Typography } from '@mui/material';

// Images & Icon
import { Add as AddIcon } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

// Custom/Additional Components
import BrandListingPage from './BrandListingPage';
import BrandDetailsTab from './form';
import BrandForm from './newCompanyForm';
import "./style.css";

function Brands() {
  const { user } = useContext(AuthContext);
  const { setNotifications } = useNotification();
  const [showBrandsDetails, setShowBrandsDetails] = useState(false);
  const [openNewCompanyForm, setOpenNewCompanyForm] = useState(false);
  const [brandData, setBrandData] = useState(null);

  const handleBrandExport = async (_event) => {
    // Brand Export Logic
    const url = process.env.REACT_APP_NODE_ENV === "development"
      ? `${process.env.REACT_APP_API_BASE_URL}/dashboard-service/brands/export`
      : `/dashboard-service/brands/export`;
    try {
      const token = localStorage.getItem("token");
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      if (response.ok) {
        const blob = await response.blob();

        const downloadUrl = window.URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = downloadUrl;
        link.download = `brand_report_${Math.floor(Date.now() / 1000)}.xlsx`; // Use epoch timestamp for filename
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(downloadUrl);
      } else {
        throw new Error("Failed to download template");
      }
    } catch (error) {
      console.error("Error while exporting brands", error);
      setNotifications({
        color: "error",
        isVisible: true,
        message: error?.message || "Error while exporting brands",
      })
    }
  }

  return (
    <DashboardLayout>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12}>
          <MDBox
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "10px",
              alignItems: "baseline",
            }}
          >
            <MDTypography
              sx={{
                fontFamily: "montserrat",
                color: "#000",
                fontWeight: "700",
              }}
              ml={1}
            >
              Brands
            </MDTypography>
            {user && user?.role === "admin" && !showBrandsDetails && (
              <Grid item xs={12} sm={6} md={6} textAlign="right">
                <MDBox
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    gap: 2,
                  }}
                >
                  <MDButton
                    type="button"
                    variant="contained"
                    color="dark"
                    sx={{
                      fontFamily: "Montserrat",
                      fontSize: "15px",
                      fontWeight: 400,
                    }}
                    circular={true}
                    startIcon={<AddIcon />}
                    onClick={handleBrandExport}
                  >
                    Export
                  </MDButton>

                  <MDButton
                    type="button"
                    variant="contained"
                    color="dark"
                    sx={{
                      fontFamily: "Montserrat",
                      fontSize: "15px",
                      fontWeight: 400,
                    }}
                    circular={true}
                    startIcon={<AddIcon />}
                    onClick={() => {
                      setBrandData(null)
                      setOpenNewCompanyForm(true)}}
                  >
                    New Company
                  </MDButton>
                </MDBox>
              </Grid>
            )}
          </MDBox>
        </Grid>
      </Grid>
      {user && ["admin"].includes(user.role) ? (
        <MDBox
          sx={{
            width: "100%",
            minHeight: "auto",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            mt: 4,
          }}
        >
          {showBrandsDetails ? (
            <>
              <IconButton
                aria-label="Go Back"
                onClick={() => {
                  setShowBrandsDetails(false);
                }}
              >
                <ArrowBackIcon />
                <Typography
                  sx={{ fontSize: "16px", ml: 1, fontWeight: "bold" }}
                >
                  Back
                </Typography>
              </IconButton>
              <BrandDetailsTab brandData={brandData} />
            </>
          ) : (
            <BrandListingPage
              setShowBrandForm={(status) => setOpenNewCompanyForm(status)}
              setBrandData={(brandData) => setBrandData(brandData)}
              setShowBrandsDetails={setShowBrandsDetails}
            />
          )}
        </MDBox>
      ) : (
        <MDBox
          sx={{
            width: "100%",
            minHeight: "auto",
            backgroundColor: "#FFFFFF",
            borderRadius: "10px",
            mt: 4,
          }}
        >
          <BrandDetailsTab />
        </MDBox>
      )}

      {openNewCompanyForm && (
        <BrandForm
          brandData={brandData}
          handleClose={() => setOpenNewCompanyForm(false)}
        />
      )}
    </DashboardLayout>
  );
}

export default Brands