import React, { useState } from "react";

// @mui material components
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Material Dashboard 2 React components
import MDTabPanel from "components/MDTabPanel";
import MDBox from "components/MDBox";

// Custom Brand Components
import BrandContactDetailsFormComponent from "./ContactDetailsForm";
import CompanyForm from "./companyForm";
import BankDetailsForm from "./BankDetailsForm";
import AssetMapping from "./Asset Mapping/assetMapping";
import CompanyVerificationForm from "./companyVerificationForm";
import StoreAgreement from "./Store Agreement";
import NodeAgreement from "./Node Agreement/nodeAgreement";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function BrandDetailsTab({ brandData }) {
  const [tabValue, setTabValue] = useState(0);
  const handleChange = (_event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <MDBox sx={{}}>
      <MDBox sx={{}}>
        <Tabs value={tabValue} onChange={handleChange} aria-label="ant example">
          <Tab
            sx={{ fontSize: "13px" }}
            label="Company Details"
            {...a11yProps(0)}
          />
          <Tab
            sx={{ fontSize: "13px" }}
            label="Company Verification"
            {...a11yProps(1)}
          />
          <Tab
            sx={{ fontSize: "13px" }}
            label="Contact Details"
            {...a11yProps(2)}
          />
          <Tab
            sx={{ fontSize: "13px" }}
            label="Bank Details"
            {...a11yProps(3)}
          />
          <Tab
            sx={{ fontSize: "13px" }}
            label="Store Agreement"
            {...a11yProps(4)}
          />
          {/* <Tab sx={{fontSize:"13px"}} label="Node Assigning" {...a11yProps(4)} /> */}
          <Tab
            sx={{ fontSize: "13px" }}
            label="Asset Mapping"
            {...a11yProps(5)}
          />
        </Tabs>
      </MDBox>
      <MDTabPanel value={tabValue} index={0}>
        <MDBox sx={{ width: "100%", minHeight: "auto", padding: "24px" }}>
          <CompanyForm handleTabChange={handleChange} brandData={brandData} />
        </MDBox>
      </MDTabPanel>
      <MDTabPanel value={tabValue} index={1}>
        <MDBox sx={{ width: "100%", minHeight: "auto", padding: "24px" }}>
          <CompanyVerificationForm
            handleTabChange={handleChange}
            brandData={brandData}
          />
        </MDBox>
      </MDTabPanel>
      <MDTabPanel value={tabValue} index={2}>
        <MDBox sx={{ width: "100%", minHeight: "auto", padding: "24px" }}>
          <BrandContactDetailsFormComponent
            handleTabChange={handleChange}
            brandData={brandData}
          />
        </MDBox>
      </MDTabPanel>
      <MDTabPanel value={tabValue} index={3}>
        <MDBox sx={{ width: "100%", minHeight: "auto", padding: "24px" }}>
          <BankDetailsForm
            handleTabChange={handleChange}
            brandData={brandData}
          />
        </MDBox>
      </MDTabPanel>
      <MDTabPanel value={tabValue} index={4}>
        <MDBox sx={{ width: "100%", minHeight: "auto", padding: "24px" }}>
          <StoreAgreement
            handleTabChange={handleChange}
            brandData={brandData}
          />
        </MDBox>
      </MDTabPanel>
      {/* <MDTabPanel value={tabValue} index={5}>
        <MDBox sx={{ width: "100%", minHeight: "auto", padding: "24px" }}>
          <NodeAgreement
            handleTabChange={handleChange}
            brandData={brandData}
          />
        </MDBox>
      </MDTabPanel> */}
      <MDTabPanel value={tabValue} index={5}>
        <MDBox sx={{ width: "100%", minHeight: "auto", padding: "24px" }}>
          <AssetMapping handleTabChange={handleChange} brandData={brandData} />
        </MDBox>
      </MDTabPanel>
    </MDBox>
  );
}
