import {
  Box,
  Button,
  CircularProgress,
  Modal,
  Typography,
} from "@mui/material";
import axios from "axios";

import useProductContext from "layouts/product/context/useProductContext";
import { useEffect, useState } from "react";
import { read, utils } from "xlsx";
import {
  getRowStyle,
  PreviewBulkProductExcelColumnDefs,
} from "./PreviewTableData";

import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useNotification } from "context";

export default function BulkProductPreviewModal({
  open,
  setOpen,
  uploadedFile,
  setForm,
}) {
  const { productContext, setProductContext } = useProductContext();
  const { setNotification } = useNotification();

  const [validationLoading, setValidationLoading] = useState(false);
  const [validatedFileJsonData, setValidatedFileJsonData] = useState(null);
  const [fileValidationError, setFileValidationError] = useState(null);

  const [uploadLoading, setUploadLoading] = useState(false);

  const createBulkProduct = async (file) => {
    const formData = new FormData();

    formData.append("file", file.file);

    const response = await axios({
      url:
        process.env.REACT_APP_NODE_ENV === "development"
          ? `${process.env.REACT_APP_API_BASE_URL}/product-service/super-admin-bulk-product`
          : `/product-service/super-admin-bulk-product`,
      method: "post",
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
      data: formData,
    });

    setProductContext({
      ...productContext,
      addedSuperAdminBulkProduct: true,
    });

    return response;
  };

  const submitFile = async () => {
    try {
      setUploadLoading(true);
      setNotification({
        color: "info",
        isVisible: true,
        message:
          "Uploading file in background, please do not close or refresh this page",
      });
      await createBulkProduct(uploadedFile);
      setNotification({
        color: "success",
        isVisible: true,
        message: "Super Admin Bulk product created succesfully",
      });
      setForm(false);
      setOpen(false);
    } catch (error) {
      const response = error.response;
      let errorMessage = error?.message;

      if (response) {
        errorMessage = response.data.message;
      }

      setNotification({
        color: "error",
        isVisible: true,
        message: errorMessage,
      });
    } finally {
      setUploadLoading(false);
    }
  };

  const validateFile = async (file) => {
    setValidationLoading(true);
    setFileValidationError(false);

    const formData = new FormData();

    formData.append("file", file.file);

    try {
      const response = await axios({
        url:
          process.env.REACT_APP_NODE_ENV === "development"
            ? `${process.env.REACT_APP_API_BASE_URL}/product-service/super-admin-bulk-product/validate`
            : `/product-service/super-admin-bulk-product/validate`,
        method: "post",
        responseType: "arraybuffer",
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
        data: formData,
      });

      const data = new Uint8Array(response.data);
      const workbook = read(data, { type: "array" });

      // Step 3: Convert the first sheet to JSON
      const firstSheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[firstSheetName];
      const jsonData = utils.sheet_to_json(worksheet);
      setValidatedFileJsonData(jsonData);
    } catch (error) {
      const response = error.response;

      setFileValidationError(response?.data?.message || error?.message);
    } finally {
      setValidationLoading(false);
    }
  };

  useEffect(() => {
    if (open && uploadedFile) {
      validateFile(uploadedFile);
    }
  }, [open, uploadedFile]);

  const invalidItemsInFile = validatedFileJsonData?.some(
    (item) => !item.validated
  );

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
      }}
      component={"paper"}
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: "80%",
          bgcolor: "background.paper",
          boxShadow: 24,
          borderRadius: "12px",
          p: 4,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Bulk Product Upload Preview
        </Typography>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {validationLoading && (
            <>
              <Typography
                textAlign={"center"}
                sx={{
                  marginRight: "1rem",
                }}
              >
                Validating file...
              </Typography>
              <CircularProgress color="info" />
            </>
          )}
        </Box>

        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {fileValidationError && (
            <Typography
              textAlign={"center"}
              sx={{
                color: "red",
              }}
            >
              Error: {fileValidationError}
            </Typography>
          )}
        </Box>
        <div
          className="ag-theme-alpine"
          style={{ height: "75vh", width: "100%" }}
        >
          <AgGridReact
            columnDefs={PreviewBulkProductExcelColumnDefs}
            rowData={validatedFileJsonData}
            getRowStyle={getRowStyle}
            rowModelType="clientSide"
            defaultColDef={{ resizable: true, sortable: true, filter: true }}
            pagination={true}
            paginationPageSizeSelector={[10, 20, 50, 100]}
            paginationPageSize={10}
          />
        </div>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Button
            onClick={submitFile}
            disabled={uploadLoading || invalidItemsInFile}
            style={{
              border: "1px solid black",

              marginTop: "1rem",
            }}
          >
            {!invalidItemsInFile
              ? "Proceed to upload file"
              : uploadLoading
              ? "Uploading..."
              : "Fix the errors in the file to proceed"}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}
