import React, { useEffect } from "react";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import {
  Grid,
  TextField,
  Typography,
  Box,
  OutlinedInput,
  InputAdornment,
  IconButton,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@mui/material";
import GalleryIcon from "@mui/icons-material/Photo";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import MDProgress from "components/MDProgress";
import { ReactComponent as ArrowDropDownIcon } from "assets/images/Arrow-Down.svg";
import { dashboardServiceClient } from "graphql/client";
import { useMutation } from "@apollo/client";
import { GET_STORES } from "services/store-service";
import { CREATE_STORE_MUTATION } from "services/store-service";
import { UPDATE_STORE_MUTATION } from "services/store-service";
import FileUploader from "components/MDFileUpload";
import MDAlert from "components/MDAlert";
import MDTypography from "components/MDTypography";
import { useNotification } from "context";
import MDButton from "components/MDButton";
const validationSchema = Yup.object().shape({
  storeName: Yup.string().required("Store Name is required").max(30, "Maximum 30 characters allowed"),
  //   shortCode: Yup.string().required("Short Code is required"),
  displayTextPrimary: Yup.string().required("Display Text Primary is required").max(80, "Maximum 80 characters allowed"),
  displayTextSecondary: Yup.string().required(
    "Display Text Secondary is required"
  ).max(80, "Maximum 80 characters allowed"),
  description: Yup.string().required("Description is required").max(300, "Maximum 300 characters allowed"),
  orderNo: Yup.number()
    .required("Order is required")
    .positive("A number must be positive.")
    .integer("Order must be an integer")
    .typeError("Order must be a number"),
  imagePath: Yup.array()
    .required("At least one image is required")
    .min(1, "At least one image is required"),
  status: Yup.string().required("Status is required"),
});

const useStoreMutation = (
  storeData,
  setNotification,
  handleTabChange,
  setStoreData,
  refetch,
  searchKeyword,
  itemsPerPage,
  skip,
  setValue
) => {
  const mutationOptions = {
    client: dashboardServiceClient,
    refetchQueries: [{ query: GET_STORES, variables: { listStoreFilter: { take: 20, skip: 0, search: "", orderby: { status: "ASC" } } } }],
  };

  const [createStoreMutation] = useMutation(
    CREATE_STORE_MUTATION,
    mutationOptions
  );
  const [updateStoreMutation] = useMutation(
    UPDATE_STORE_MUTATION,
    mutationOptions
  );

  const handleSubmit = (values) => {
    const payload = {
      name: values?.storeName,
      genesysId: values?.genesysId,
      optcultureId: values?.optcultureId,
      description: values?.description,
      primaryText: values?.displayTextPrimary,
      secondaryText: values?.displayTextSecondary,
      displayOrder: +values?.orderNo,
      status: values?.status?.toLowerCase(),
      imagePath: values?.imagePath,
      address1: storeData?.id && storeData?.address1 ? storeData?.address1 : "",
      address2: storeData?.id && storeData?.address2 ? storeData?.address2 : "",
      city: storeData?.id && storeData?.city ? storeData?.city : "",
      latitude: storeData?.id && storeData?.latitude ? storeData?.latitude : "",
      longitude: storeData?.id && storeData?.longitude ? storeData?.longitude : "",
      pincode: storeData?.id && storeData?.pincode ? storeData?.pincode : "",
      state: storeData?.id && storeData?.state ? storeData?.state : "",
    };

    const mutation =
      storeData && storeData.id ? updateStoreMutation : createStoreMutation;
    const mutationInput =
      storeData && storeData.id
        ? { updateStoreInput: { id: storeData.id, ...payload } }
        : { createStoreInput: payload };

    mutation({ variables: mutationInput })
      .then((res) => {
        setValue(1);
        setStoreData(
          res.data[storeData && storeData.id ? "updateStore" : "createStore"]
        );
        setNotification({
          color: "success",
          isVisible: true,
          message:
            storeData && storeData.id
              ? "Store info data updated successfully"
              : "Store info data created successfully",
        });
      })
      .catch((error) => {
        console.error(" Error:", error?.message);
        setNotification({
          color: "error",
          isVisible: true,
          message: error?.message || "Something went wrong",
        });
      }).finally(() => refetch({
        variables: {
          listStoreFilter: { take: itemsPerPage, skip: skip, search: searchKeyword, orderby: { status: "ASC" } }
        }
      }));
  };

  return handleSubmit;
};

const FormikField = ({ name, label, touched, errors, ...rest }) => (
  <Field
    name={name}
    as={TextField}
    label={label}
    fullWidth
    error={touched[name] && !!errors[name]}
    helperText={touched[name] && errors[name]}
    {...rest}
  />
);

const StoreInfoComponent = ({
  handleClose,
  storeData,
  handleTabChange,
  setStoreData,
  refetch,
  searchKeyword,
  itemsPerPage,
  skip,
  value,
  setValue,
}) => {
  const { setNotification } = useNotification();

  const handleSubmit = useStoreMutation(
    storeData,
    setNotification,
    handleTabChange,
    setStoreData, refetch, searchKeyword,
    itemsPerPage,
    skip,
    setValue
  );
  return (
    <Formik
      initialValues={{
        storeName: storeData ? storeData.name : "",
        shortCode: storeData ? storeData.code : "",
        displayTextPrimary: storeData ? storeData.primaryText : "",
        displayTextSecondary: storeData ? storeData.secondaryText : "",
        description: storeData ? storeData.description : "",
        orderNo: storeData ? storeData.displayOrder : "",
        genesysId: storeData ? storeData.genesysId : "",
        optcultureId: storeData ? storeData.optcultureId : "",
        imagePath: storeData && storeData?.image ? storeData?.image.map((item) => item.path) : [],
        status: storeData ? storeData?.status?.toLowerCase() : "inactive"
      }}
      validationSchema={validationSchema}
      onSubmit={(values) => {
        handleSubmit(values);
      }}
    >
      {({ handleChange, values, errors, touched, setFieldValue, setFieldTouched, validateField }) => (
        <Form>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <FormikField
                name="storeName"
                label="Store Name"
                touched={touched}
                errors={errors}
              />
            </Grid>
            <Grid item xs={6}>
              <FormikField
                disabled={true}
                name="shortCode"
                label="Short Code"
                touched={touched}
                errors={errors}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikField
                name="displayTextPrimary"
                label="Display Text Primary"
                touched={touched}
                errors={errors}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikField
                name="displayTextSecondary"
                label="Display Text Secondary"
                touched={touched}
                errors={errors}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikField
                name="description"
                label="Description"
                touched={touched}
                errors={errors}
              />
            </Grid>
            <Grid item xs={12}>
              <FormikField
                name="orderNo"
                label="Order No."
                touched={touched}
                errors={errors}
              />
            </Grid>
            <Grid item xs={12}>

              <FileUploader
                isMultiple={true}
                fieldName={"ImagePath"}
                preLoadedFile={!!values.imagePath ? values.imagePath : null}
                signedUrlCallback={(newUrl, index) => {
                  const updatedImagePathArray = [
                    ...values.imagePath,
                  ];
                  if (newUrl) {
                    // Update the URL at the given index
                    updatedImagePathArray[index] = newUrl;
                  } else {
                    // Delete the URL at the given index
                    updatedImagePathArray.splice(index, 1);
                  }
                  setFieldValue(
                    "imagePath",
                    updatedImagePathArray
                  );
                }}
                isFieldValid={touched?.imagePath && errors?.imagePath}
              />
              {touched?.imagePath && errors?.imagePath && (
                <MDTypography color="error" fontWeight="light" fontSize="12px">
                  {errors.imagePath}
                </MDTypography>
              )}
            </Grid>
            <Grid item xs={6}>
              <FormikField
                name="optcultureId"
                label="Opt. Culture ID"
                touched={touched}
                errors={errors}
              />
            </Grid>
            <Grid item xs={6}>
              <FormikField
                name="genesysId"
                label="Ginesys ID"
                touched={touched}
                errors={errors}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="demo-simple-select-label">
                  Status
                </InputLabel>
                <Select
                  as={Select}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="status"
                  label="Status"
                  IconComponent={() => (
                    <ArrowDropDownIcon style={{ marginRight: "18px" }} />
                  )}
                  value={values.status?.toLowerCase()}
                  sx={{ height: 40 }}
                  onChange={(e) => {
                    setFieldValue("status", e.target.value);
                    setFieldTouched(`status`, true, false);
                    validateField(`status`, e.target.value);
                  }}
                  error={
                    touched.status && Boolean(errors.status)
                  }
                  helperText={
                    touched.status && errors.status
                  }
                >
                  <MenuItem value="active">Active</MenuItem>
                  <MenuItem value="inactive">InActive</MenuItem>
                  <MenuItem value="upcoming">Upcoming</MenuItem>
                </Select>
                {touched.status && errors.status ? (
                  <Typography fontSize={12} color="error">
                    {errors.status}
                  </Typography>
                ) : null}
              </FormControl>
            </Grid>
            {/* <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{ padding: "8px 0" }}
                >
                  Status
                </InputLabel>
                <Field
                  as={Select}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="storeName"
                  onChange={handleChange}
                  sx={{ padding: "10px", marginTop: "10px" }}
                >
                  <MenuItem value={10}></MenuItem>
                  <MenuItem value={20}>Store 2</MenuItem>
                  <MenuItem value={30}>Store 3</MenuItem>
                </Field>
              </FormControl>
            </Grid> */}

            <Grid container spacing={1}>
              <Grid container xs={12} sx={{ mt: 4, ml: 2, pb: 2 }} flexDirection={"row-reverse"} gap={3}>
                <MDButton
                  type="submit"
                  variant="contained"
                  circular={true}
                  color="dark"
                >
                  Save
                </MDButton>
                <MDButton color="dark" onClick={handleClose} variant="outlined" circular={true}>
                  Cancel
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default StoreInfoComponent;
