import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Button,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormHelperText,
  Checkbox,
  Autocomplete,
  FormGroup,
  IconButton,
  Icon,
} from "@mui/material";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
import { useMutation, useQuery } from "@apollo/client";
import { dashboardServiceClient } from "graphql/client";
import FileUploader from "components/MDFileUpload";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Formik, Form, Field, FieldArray } from "formik";
import * as Yup from "yup";
import { ReactComponent as ArrowDropDownIcon } from "assets/images/Arrow-Down.svg";
import Loader from "components/MDLoader";
import { GET_STORES_LIST } from "services/store-service";
import { GET_BRAND_CATEGORY_STORE } from "services/brand-service";
import {
  GET_CATEGORY_PRODUCT_LIST,
  UPDATE_BRAND_CATEGORY_STORE,
  CREATE_BRAND_CATEGORY_STORE,
} from "services/brand-service";
import { useNotification } from "context";
import MDButton from "components/MDButton";
import { LIST_BRAND_STORE_FOR_NODE_AGREEMENT } from "services/brand-service";
import { GET_CATEGORIES } from "services/product-category-service";
dayjs.extend(isSameOrAfter);

const user =
  localStorage.getItem("userDetails") &&
  localStorage.getItem("userDetails") !== "undefined"
    ? JSON.parse(localStorage.getItem("userDetails"))
    : {};

const useNodeCategoryMutation = (
  categoryData,
  handleClose,
  setNotification,
  brandData,
  onFormSubmit
) => {
  const mutationOptions = {
    client: dashboardServiceClient,
    refetchQueries: [
      {
        query: GET_BRAND_CATEGORY_STORE,
        variables: {
          take: 20,
          skip: 0,
          search: "",
          filter: { brand: { id: brandData?.id }, isDeleted: false },
          fetchPolicy: "network-only",
          relations: [
            "category",
            "category.categoryProductAttribute",
            "brandStore",
            "brandStore.store",
            "brand",
            "store",
          ],
        },
      },
    ],
  };

  const [createNodeCategoryMutation] = useMutation(
    CREATE_BRAND_CATEGORY_STORE,
    mutationOptions
  );
  const [updateNodeCategoryMutation] = useMutation(
    UPDATE_BRAND_CATEGORY_STORE,
    mutationOptions
  );

  const handleSubmit = (values, setSubmitting) => {
    const payload = {
      start: values.startDate,
      end: values.endDate,
      categoryId: values.categoryNode,
      brandStoreId: values?.brandStoreId,
      brandId: user && user.role === "admin" ? brandData?.id : user.userId,
      storeId: values.storeName,
      images: values.categoryPath
        ? {
            path: values?.categoryPath[0] || "",
            publicurl: values?.categoryPath[1] || "",
          }
        : {},
    };

    const mutation =
      categoryData && categoryData.id
        ? updateNodeCategoryMutation
        : createNodeCategoryMutation;
    const mutationInput =
      categoryData && categoryData.id
        ? { updateBrandCategoryStoreInput: { id: categoryData.id, ...payload } }
        : { createBrandCategoryStoreInput: payload };

    mutation({
      variables: mutationInput,
      onCompleted: (res) => {
        handleClose();
        setNotification({
          color: "success",
          isVisible: true,
          message:
            categoryData && categoryData?.id
              ? "Category Node updated successfully"
              : "Category Node created successfully",
        });
        onFormSubmit();
      },
      onError: (error) => {
        console.error("Error:", error?.message);
        setNotification({
          color: "error",
          isVisible: true,
          message: error?.message || "Something went wrong",
        });
      },
    }).finally(() => {
      setSubmitting(false);
    });
  };

  return handleSubmit;
};

const NodeCategoryForm = ({
  categoryData,
  handleClose,
  brandData,
  onFormSubmit,
}) => {
  const { setNotification } = useNotification();
  const isAdmin = user && user?.role === "admin" ? true : false;
  const [storeDates, setStoreDates] = useState({
    minDate: null,
    maxDate: null,
  });
  const {
    loading: catLoading,
    error: catError,
    data: catData,
    refetch: catRefetch,
  } = useQuery(GET_CATEGORIES, {
    client: dashboardServiceClient,
    variables: {
      listCategoryProductFilter: {
        take: 100000,
        skip: 0,
        search: "",
        parentIsNull: true,
        orderby: { isDeleted: "ASC", updatedAt: "DESC" },
      },
      fetchPolicy: "network-only",
    },
  });
  const {
    loading: storeLoading,
    error: storeError,
    data: storeList,
  } = useQuery(LIST_BRAND_STORE_FOR_NODE_AGREEMENT, {
    client: dashboardServiceClient,
    variables: {
      listBrandStoreFilter: {
        take: 10,
        skip: 0,
        filter: categoryData
          ? { brand: { id: brandData.id } }
          : { status: ["active", "upcoming"], brand: { id: brandData.id } },
      },
    },
  });
  const validationSchema = (data) =>
    Yup.object({
      storeName: Yup.string().required("Store name is required"),
      categoryNode: Yup.string().required("Category Node is required"),
      startDate: Yup.date()
        .required("Start date is required")
        .test(
          "startDate",
          `Start date must be on or after ${
            storeDates.minDate
              ? storeDates.minDate.format("DD-MM-YYYY")
              : dayjs().format("DD-MM-YYYY")
          } and not in the past`,
          (value) => {
            const today = dayjs().startOf("day");
            const isValid =
              dayjs(value).isSameOrAfter(today, "day") &&
              dayjs(value).isSameOrAfter(storeDates.minDate || today, "day");
            return isValid;
          }
        ),
      endDate: Yup.date()
        .required("End date is required")
        .min(
          Yup.ref("startDate"),
          "End date must be at least one day after the start date"
        )
        .max(
          storeDates.maxDate || dayjs().add(1, "year"),
          `End date must be on or before ${
            storeDates.maxDate
              ? storeDates.maxDate.format("DD-MM-YYYY")
              : dayjs().add(1, "year").format("DD-MM-YYYY")
          }`
        ),
    });
  const handleStoreChange = (selectedStore, setFieldValue) => {
    setFieldValue("storeName", selectedStore.store.id);
    setFieldValue("brandStoreId", selectedStore?.id);
    setFieldValue("startDate", null);
    setFieldValue("endDate", null);

    if (selectedStore) {
      setStoreDates({
        minDate: dayjs(selectedStore.start),
        maxDate: dayjs(selectedStore.end),
      });
    } else {
      setStoreDates({ minDate: null, maxDate: null });
    }
  };
  useEffect(() => {
    if (storeList && categoryData?.store?.id) {
      const selectedStore = storeList?.brandStores?.results.find(
        (item) =>
          item.store.id === categoryData.store.id &&
          item.id === categoryData.brandStore?.id
      );
      if (selectedStore && selectedStore?.id) {
        // Ensure the store dates are set only when selectedStore is properly found
        setStoreDates({
          minDate: dayjs(selectedStore.start),
          maxDate: dayjs(selectedStore.end),
        });
      }
    }
  }, [storeList, categoryData]);

  const handleSubmit = useNodeCategoryMutation(
    categoryData,
    handleClose,
    setNotification,
    brandData,
    onFormSubmit
  );

  const options = catData?.categoryProduct?.results || [];
  const brandStoreList = storeList?.brandStores?.results?.map((item) => item);
  if (storeLoading) return <Loader />;
  if (storeError || catError) return console.error(storeError || error);
  return (
    <Formik
      initialValues={{
        id: categoryData?.id || null,
        storeName: categoryData?.store.id || "",
        brandStoreId: categoryData?.brandStore?.id || "",
        categoryNode: categoryData?.category.id || "",
        startDate: categoryData?.start || "",
        endDate: categoryData?.end || "",
        categoryPath: [
          categoryData && categoryData.images ? categoryData?.images.path : "",
          categoryData && categoryData.images
            ? categoryData?.images.publicurl
            : "",
        ],
      }}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        handleSubmit(values, setSubmitting);
      }}
    >
      {({
        values,
        errors,
        touched,
        setFieldValue,
        handleBlur,
        isValid,
        isSubmitting,
      }) => (
        <Form>
          <Grid container spacing={2} sx={{ padding: "20px" }}>
            <Grid item xs={12}>
              <FormControl
                fullWidth
                error={touched.storeName && Boolean(errors.storeName)}
              >
                <InputLabel id="demo-simple-select-label">
                  Store Name
                </InputLabel>
                <Select
                  as={Select}
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  name="storeName"
                  label="Store Name"
                  IconComponent={() => (
                    <ArrowDropDownIcon
                      style={{ marginRight: "18px", marginTop: "10px" }}
                    />
                  )}
                  value={
                    brandStoreList.find(
                      (store) => store?.id === values?.brandStoreId
                    ) || ""
                  } // Reference brandStoreId
                  onChange={(e) =>
                    handleStoreChange(e.target.value, setFieldValue)
                  }
                  onBlur={handleBlur}
                  error={touched.storeName && Boolean(errors.storeName)}
                  sx={{ height: 40 }}
                >
                  {brandStoreList.map((brandStore) => (
                    <MenuItem key={brandStore.id} value={brandStore}>
                      {`${brandStore.store.name} - ${dayjs(
                        brandStore.start
                      ).format("DD-MM-YYYY")} to ${dayjs(brandStore.end).format(
                        "DD-MM-YYYY"
                      )}`}
                    </MenuItem>
                  ))}
                </Select>
                {touched.storeName && errors.storeName ? (
                  <FormHelperText>{errors.storeName}</FormHelperText>
                ) : null}
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={options}
                getOptionLabel={(option) => option.title || ""}
                value={
                  options.find((option) => option.id === values.categoryNode) ||
                  null
                }
                onChange={(event, newValue) => {
                  setFieldValue("categoryNode", newValue?.id || "");
                }}
                disable={!isAdmin}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Select Category"
                    variant="outlined"
                    fullWidth
                    error={touched.categoryNode && Boolean(errors.categoryNode)}
                    helperText={touched.categoryNode && errors.categoryNode}
                  />
                )}
              />
            </Grid>
            <Grid item xs={15}>
              <Typography fontSize={12} fontWeight={"bold"}>
                Upload Hero Image
              </Typography>
              <FileUploader
                fieldName={"Upload Hero Image"}
                isMultiple={false}
                preLoadedFile={
                  !!values.categoryPath ? [values.categoryPath[0]] : null
                }
                signedUrlCallback={(newUrl, index, publicUrl, filename) => {
                  setFieldValue("categoryPath", [newUrl, publicUrl]);
                }}
                isFieldValid={touched?.categoryPath && errors?.categoryPath}
              />
              {touched?.categoryPath && errors?.categoryPath && (
                <Typography fontSize={12} color="error">
                  {errors?.categoryPath}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12}>
              <Typography
                variant="body2"
                color="textSecondary"
                sx={{
                  fontSize: "14px",
                  marginTop: "16px",
                  backgroundColor: "#FAFAFA",
                  padding: "10px",
                  borderRadius: "10px",
                  overflow: "auto",
                }}
              >
                <strong>Note</strong>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  component="div"
                  sx={{ fontSize: "12px", marginTop: "8px" }}
                >
                  <ul
                    style={{
                      paddingLeft: "20px",
                      marginTop: "5px",
                      marginBottom: 0,
                    }}
                  >
                    <li>
                      Category start date should be greater than or equal to
                      store agreement start date
                    </li>
                    <li>
                      Category end date should be lesser than or equal to store
                      agreement store end date
                    </li>
                  </ul>
                </Typography>
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    slotProps={
                      errors.startDate
                        ? {
                            textField: {
                              error: !!touched.startDate && errors.startDate,
                              helperText: errors.startDate,
                            },
                          }
                        : null
                    }
                    label="Start Date"
                    name="startDate"
                    value={values?.startDate ? dayjs(values?.startDate) : null}
                    minDate={storeDates.minDate || dayjs().startOf("day")}
                    maxDate={storeDates.maxDate}
                    onChange={(newValue) =>
                      setFieldValue(
                        "startDate",
                        dayjs(newValue).format("YYYY-MM-DD")
                      )
                    }
                    disabled={!values?.storeName}
                    renderInput={(params) => (
                      <Field
                        as={TextField}
                        {...params}
                        fullWidth
                        error={
                          touched?.categories[index]?.startDate &&
                          Boolean(errors?.startDate)
                        }
                        helperText={touched?.startDate && errors?.startDate}
                      />
                    )}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid item xs={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DemoContainer components={["DatePicker"]}>
                  <DatePicker
                    format="DD/MM/YYYY"
                    slotProps={
                      errors.endDate
                        ? {
                            textField: {
                              error: !!touched.endDate && errors.endDate,
                              helperText: errors.endDate,
                            },
                          }
                        : null
                    }
                    label="End Date"
                    name="endDate"
                    value={values.endDate ? dayjs(values.endDate) : null}
                    minDate={dayjs(values.startDate)}
                    maxDate={storeDates.maxDate}
                    onChange={(newValue) =>
                      setFieldValue(
                        "endDate",
                        dayjs(newValue).format("YYYY-MM-DD")
                      )
                    }
                    disabled={!values?.storeName}
                    renderInput={(params) => (
                      <Field
                        as={TextField}
                        {...params}
                        fullWidth
                        error={touched?.endDate && Boolean(errors?.endDate)}
                        helperText={touched?.endDate && errors?.endDate}
                      />
                    )}
                  />
                </DemoContainer>
              </LocalizationProvider>
            </Grid>
            <Grid container spacing={1}>
              <Grid
                container
                flexDirection={"row-reverse"}
                gap={2}
                xs={12}
                sx={{ mt: 4, ml: 2, pb: 2 }}
              >
                {isAdmin ? (
                  <MDButton
                    type="submit"
                    variant="contained"
                    circular={true}
                    color="dark"
                    disabled={isSubmitting}
                  >
                    Save
                  </MDButton>
                ) : (
                  <></>
                )}
                <MDButton
                  onClick={handleClose}
                  variant={"outlined"}
                  circular={true}
                  color="dark"
                >
                  {isAdmin ? "Cancel" : "Close"}
                </MDButton>
              </Grid>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default NodeCategoryForm;
