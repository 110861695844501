import * as React from "react";
import { DataGrid } from "@mui/x-data-grid";
import Chip from "@mui/material/Chip";
import {
  Grid,
  Pagination as MuiPagination,
  Box,
  TablePagination,
  IconButton,
  Icon,
} from "@mui/material";
import { useLazyQuery, useQuery } from "@apollo/client";
import { GET_CUSTOMER_LIST } from "services/customer-service";
import { dashboardServiceClient } from "graphql/client";
import Loader from "components/MDLoader";
import { SearchContext, FilterContext } from "context";
import { useContext, useEffect } from "react";
import AgGrid from "components/MDGrid";
import { createOrderByFromOrderModel } from "./utils";
import dayjs from "dayjs";
import { ReactComponent as previewIcon } from "assets/images/previewIcon.svg";

const columns = [
  {
    field: "firstName",
    headerName: "First Name",
    width: 200,
    valueGetter: (params) => {
      const firstName = params.row.firstName || "";
      const lastName = params.row.lastName || "";
      return firstName || lastName ? `${firstName} ${lastName}` : "N/A";
    },
  },
  {
    field: "lastName",
    headerName: "Last Name",
    width: 200,
    valueGetter: (params) => {
      const firstName = params.row.firstName || "";
      const lastName = params.row.lastName || "";
      return firstName || lastName ? `${firstName} ${lastName}` : "N/A";
    },
  },
  { field: "accountId", headerName: "Account ID", width: 130 },
  { field: "email", headerName: "Email", width: 200 },
  { field: "mobile", headerName: "Phone", width: 250 },
  {
    field: "walletBalance",
    headerName: "Wallet Balance",
    width: 160,
    valueGetter: (params) => params.row.account.balance,
  },
  { field: "loginDate", headerName: "Last Login", width: 160 },
  {
    field: "status",
    headerName: "Status",
    width: 130,
    renderCell: (params) => (
      <Chip
        color={params?.value === true ? "success" : "error"}
        label={params?.value === true ? "Active" : "Inactive"}
        variant="outlined"
      />
    ),
  },
  {
    field: "address1",
    headerName: "Address Line 1",
    width: 200,
    valueGetter: (params) => params.row.account?.address[0]?.addressLine1,
  },
  {
    field: "address2",
    headerName: "Address Line 2",
    width: 200,
    valueGetter: (params) => params.row.account?.address[0]?.addressLine2,
  },
  {
    field: "city",
    headerName: "City",
    width: 130,
    valueGetter: (params) => params.row.account?.address[0]?.city,
  },
  {
    field: "state",
    headerName: "State",
    width: 130,
    valueGetter: (params) => params.row.account?.address[0]?.state,
  },
  {
    field: "pincode",
    headerName: "Pincode",
    width: 130,
    valueGetter: (params) => params.row.account?.address[0]?.pincode,
  },

  {
    field: "channel",
    headerName: "Channel",
    width: 130,
    valueGetter: (params) => params.row.account.regSource,
  },
];
const columnDefs = [
  {
    field: "firstName",
    headerName: "First Name",
    width: 200,
    allowSearch: true,
  },
  {
    field: "lastName",
    headerName: "Last Name",
    width: 200,
    allowSearch: true,
  },
  { field: "accountId", headerName: "Account ID", width: 130 },
  { field: "email", headerName: "Email", width: 200 },
  { field: "mobile", headerName: "Phone", width: 250 },
  {
    field: "walletBalance",
    headerName: "Wallet Balance",
    width: 160,
    filter: "agNumberColumnFilter",
    getFilterObj: (value) => ({
      account: { balance: value?.filter },
    }),
    valueGetter: (params) => params.data.account.balance,
  },
  {
    field: "loginDate",
    headerName: "Last Login",
    width: 160,
    filter: "agDateColumnFilter",
    filterParams: {
      comparator: (filterLocalDateAtMidnight, cellValue) => {
        var dateAsString = cellValue;
        if (dateAsString == null) return -1;
        var dateParts = dateAsString.split("/");
        var cellDate = new Date(
          Number(dateParts[2]),
          Number(dateParts[1]) - 1,
          Number(dateParts[0])
        );

        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          return 0;
        }

        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        }

        if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
        return 0;
      },
      minValidYear: 2023,
      inRangeFloatingFilterDateFormat: "Do MMM YYYY",
      filterOptions: ["inRange"],
      defaultOption: "inRange",
      maxNumConditions: 1,
      inRangeInclusive: true,
    },
    valueGetter: (params) =>
      params?.data?.loginDate
        ? dayjs(params?.data?.loginDate).format("DD/MM/YYYY")
        : "N/A",
    getFilterObj: (value) => ({ loginDate: value }),
  },
  {
    field: "lastOfflineActivity",
    headerName: "Last Offline Activity",
    width: 160,
    filter: "agDateColumnFilter",
    filterParams: {
      comparator: (filterLocalDateAtMidnight, cellValue) => {
        var dateAsString = cellValue;
        if (dateAsString == null) return -1;
        var dateParts = dateAsString.split("/");
        var cellDate = new Date(
          Number(dateParts[2]),
          Number(dateParts[1]) - 1,
          Number(dateParts[0])
        );

        if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
          return 0;
        }

        if (cellDate < filterLocalDateAtMidnight) {
          return -1;
        }

        if (cellDate > filterLocalDateAtMidnight) {
          return 1;
        }
        return 0;
      },
      minValidYear: 2023,
      inRangeFloatingFilterDateFormat: "Do MMM YYYY",
      filterOptions: ["inRange"],
      defaultOption: "inRange",
      maxNumConditions: 1,
      inRangeInclusive: true,
    },
    valueGetter: (params) =>
      params?.data?.lastOfflineActivity
        ? dayjs(params?.data?.lastOfflineActivity).format("DD/MM/YYYY")
        : "N/A",
    getFilterObj: (value) => ({ loginDate: value }),
  },
  {
    field: "status",
    headerName: "Status",
    width: 130,
    filter: "agSetColumnFilter",
    filterParams: {
      values: ["Active", "Inactive"],
      maxNumConditions: 1,
    },
    getFilterObj: (filter) => {
      return { status: filter.values?.map((val) => val == "Active") };
    },
    cellRenderer: (params) => {
      // const isActive = params.value === true;
      // return <span class="ag-cell-status ${isActive ? 'active' : 'inactive'}">${isActive ? 'Active' : 'Inactive'}</span>
      return (
        <Chip
          color={params?.value === true ? "success" : "error"}
          label={params?.value === true ? "Active" : "Inactive"}
          variant="outlined"
        />
      );
    },
  },
  {
    field: "account.address.addressLine1",
    headerName: "Address Line 1",
    width: 200,
    valueGetter: (params) => params.data.account?.address[0]?.addressLine1,
  },
  {
    field: "account.address.addressLine2",
    headerName: "Address Line 2",
    width: 200,
    valueGetter: (params) => params.data.account?.address[0]?.addressLine2,
  },
  {
    field: "account.address.city",
    headerName: "City",
    width: 130,
    valueGetter: (params) => params.data.account?.address[0]?.city,
  },
  {
    field: "account.address.state",
    headerName: "State",
    width: 130,
    valueGetter: (params) => params.data.account?.address[0]?.state,
  },
  {
    field: "account.address.pincode",
    headerName: "Pincode",
    width: 130,
    valueGetter: (params) => params.data.account?.address[0]?.pincode,
  },
  {
    field: "account.regSource",
    headerName: "Channel",
    width: 130,
    valueGetter: (params) => params.data.account.regSource,
  },
  {
    field: "actions",
    headerName: "Actions",
    width: 140,
    sortable: false,
    filter: false,
    cellRenderer: (params) => (
      <IconButton aria-label="edit">
        <Icon component={previewIcon} fontSize="small" />
      </IconButton>
    ),
  },
];

function CustomerLandingPage({ onRowClick }) {
  const { searchKeyword, setSearchKeyword } = useContext(SearchContext);
  const [paginationModel, setPaginationModel] = React.useState({
    page: 0,
    pageSize: 20,
  });

  const { loading, error, data, refetch } = useQuery(GET_CUSTOMER_LIST, {
    client: dashboardServiceClient,
    variables: {
      take: paginationModel.pageSize,
      skip: paginationModel.page * paginationModel.pageSize,
      search: searchKeyword,
    },
    fetchPolicy: "network-only", // Ignore cache for this query
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setRowCount(data?.customerList?.total);
    },
  });
  const [rowCount, setRowCount] = React.useState(
    data?.customerList?.total || 0
  );

  const [fetchCustomers] = useLazyQuery(GET_CUSTOMER_LIST, {
    client: dashboardServiceClient,
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
  });

  React.useEffect(() => {
    setRowCount((prevRowCount) =>
      data?.customerList?.total !== undefined
        ? data?.customerList?.total
        : prevRowCount
    );
  }, [data?.customerList?.total, setRowCount]);
  if (loading)
    return (
      <>
        <Loader />
      </>
    );
  if (error) return <p>Error: {error?.message}</p>;

  const customers = data?.customerList?.results;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <AgGrid
      columns={columnDefs}
      allowSearch
      readMethod={{
        fetchFn: fetchCustomers,
        inputParser: ({
          startRow,
          endRow,
          filter,
          sortModel,
          searchCols,
          searchBy,
        }) => {
          return {
            take: endRow - startRow,
            skip: startRow,
            search: searchKeyword,
            filter,
            orderby: createOrderByFromOrderModel(sortModel, columnDefs),
            searchCols,
            searchBy,
          };
        },
        resultsParser: ({ data }) => ({
          results: data?.customerList?.results,
          total: data?.customerList?.total,
        }),
      }}
      gridProps={{
        onRowClicked: ({ data }) => onRowClick({ row: data }),
      }}
      defaultColDefProp={{
        filter: "agTextColumnFilter",
        filterParams: {
          filterOptions: ["contains"],
          defaultOption: "contains",
          maxNumConditions: 1,
        },
        suppressMenu: true,
        floatingFilterComponentParams: { suppressFilterButton: true },
      }}
    />
  );
}

export default CustomerLandingPage;
