import React, { useEffect, useState } from "react";

// @mui material components
import {
  Grid,
  Box,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Chip,
  Icon,
  Drawer,
  Select,
  MenuItem,
  FormControl,
  Pagination,
} from "@mui/material";
import { Add as AddIcon, Store as StoreIcon } from "@mui/icons-material";
import { ReactComponent as searchIcon } from "assets/images/searchIcon.svg";
// Custom components and services
import DashboardLayout from "ui/LayoutContainers/DashboardLayout";
import DashboardNavbar from "ui/Navbars/DashboardNavbar";
import BasicTabs from "./tabsForm";
import { GET_STORES } from "services/store-service";
import { dashboardServiceClient } from "graphql/client";
import { useLazyQuery, useQuery } from "@apollo/client";
import Loader from "components/MDLoader";
import { SearchContext, FilterContext } from "context";
import { useContext } from "react";
import MDButton from "components/MDButton";
import { GET_STORE_LOCATION } from "services/store-service";

const StoreCard = ({ item, handleCardClick }) => (
  <Grid item xs={12} sm={6} md={3}>
    <Card sx={{ minWidth: 100, borderRadius: 0, overflowY: "auto" }}>
      <CardContent>
        <Chip
          style={{ borderRadius: 0 }}
          label={
            item.status === "Active"
              ? "Active"
              : item.status === "Upcoming"
              ? "Upcoming"
              : "Inactive"
          }
          color={
            item.status === "Active"
              ? "success"
              : item.status === "Upcoming"
              ? "warning"
              : "error"
          }
          variant="filled"
        />
        <Typography
          sx={{
            fontSize: 16,
            mt: 2,
            mb: 2,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          variant="h5"
          gutterBottom
        >
          {item.name} , {item.city}
          <Typography
            sx={{
              fontSize: 10,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
            component="div"
            color="text.secondary"
          >
            {item.code}
          </Typography>
        </Typography>
        <Typography
          sx={{
            fontSize: 13,
            mt: 2,
            fontFamily: "Inter, sans-serif",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
          component="div"
          color="text.secondary"
        >
          {item.description}
        </Typography>
      </CardContent>
      <CardActions>
        <Button size="small" onClick={() => handleCardClick(item)}>
          {"Details >"}
        </Button>
      </CardActions>
    </Card>
  </Grid>
);

function Stores() {
  const [open, setOpen] = useState(false);
  const [storeData, setStoreData] = useState(null);
  const [storeLocation, setStoreLocation] = useState(null);
  const [storeList, setStoreList] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(20); // You can set this as you prefer
  const [value, setValue] = useState(0);
  const { searchKeyword, setSearchKeyword } = useContext(SearchContext);

  const totalPages = storeList
    ? Math.ceil(storeList.stores.total / itemsPerPage)
    : 0;
  const skip = (currentPage - 1) * itemsPerPage;
  const [getStoreDetails, { loading, error, data, refetch }] = useLazyQuery(
    GET_STORES,
    {
      client: dashboardServiceClient,
      variables: {
        listStoreFilter: {
          take: itemsPerPage,
          skip: skip,
          search: searchKeyword,
          orderby: { status: "ASC" },
          // filter:selectedBrands || null
        },
      }, // Replace 'YOUR_SEARCH_TERM' with the desired search term or variable.
    }
  );

  const {
    refetch: storeLocationRefetch,
  } = useQuery(GET_STORE_LOCATION, {
    client: dashboardServiceClient,
    variables: {
      take: 1000,
      skip: 0,
      filter: {
        isDeleted: false,
        subLocations: {
          isDeleted: false,
          assetTypes: {
            isDeleted: false,
          }
        }
      },
    },
    fetchPolicy: "network-only",
    notifyOnNetworkStatusChange: true,
    onCompleted: (data) => {
      setStoreLocation(data?.storeLocations?.results);
    },
  });

  useEffect(() => {
    getStoreDetails();
  }, []);

  // StoreLocation
  useEffect(() => {
    if (data) {
      setStoreList(data);
      if (storeData) {
        const store = data?.stores.results.find(
          (store) => store.id === storeData.id
        );
        if (store) {
          setStoreData(store);
        }
      }
    }
  }, [data]);
useEffect(()=>{
  if(!open && !storeData){
    setStoreData(null);
    setValue(0)
  }
},[open ,storeData])
  const handleItemsPerPageChange = (event) => {
    const newItemsPerPage = event.target.value;
    const newTotalPages = Math.ceil(storeList.stores.total / newItemsPerPage);
    setItemsPerPage(newItemsPerPage);
    if (currentPage > newTotalPages) {
      setCurrentPage(1); // Reset to first page
    }
  };

  useEffect(() => {
    setCurrentPage(1);
  }, [searchKeyword]);

  useEffect(() => {
    // Reset search keyword when entering the Brands tab
    setSearchKeyword("");
    setTimeout(() => {
      if (refetch) {
        refetch();
      }
    }, 100);
  }, []);

  if (loading)
    return (
      <>
        <Loader />
      </>
    );
  if (error) return <p>Error: {error?.message}</p>;

  const handleCardClick = (item) => {
    setStoreData(item);
    setOpen(true);
    setValue(0)
  };

  const handleSetStoreData = (_storeData) => {
    setStoreData(_storeData);
    getStoreDetails();
  };

  return (
    <DashboardLayout>
      {/* <DashboardNavbar /> */}

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6}>
          <Typography sx={{fontFamily:"montserrat", color:"#000", fontWeight:"700"}} ml={1}>Stores</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={6} textAlign="right">
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {/* <Icon sx={{ mr: 2 }} component={searchIcon} fontSize="large" /> */}
            <MDButton
              type="button"
              variant="contained"
              color="dark"
              circular={true}
              startIcon={<AddIcon />}
              onClick={() => {
                setOpen(true);
                setStoreData(null);
                setValue(0)
              }}
            >
              New Store
            </MDButton>
          </Box>
        </Grid>
        {storeList &&
          storeList.stores.results.map((item, index) => (
            <StoreCard
              item={item}
              key={index}
              handleCardClick={handleCardClick}
            />
          ))}
        <Drawer
          anchor="right"
          open={open}
          onClose={() => setOpen(false)}
          PaperProps={{ style: { width: "50%" } }}
        >
          <Grid
            container
            sx={{ backgroundColor: "#F0F2F5", pb: 3, pt: 2, pl: 2 }}
          >
            <Grid item xs={12}>
              &nbsp;
            </Grid>
            <Grid item xs={12}>
              <Icon
                sx={{ mr: 2, marginLeft: "10px" }}
                component={StoreIcon}
                fontSize="large"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ marginLeft: "10px" }}>
                Store Detail
              </Typography>
            </Grid>
          </Grid>
          <Box sx={{ width: "100%", minHeight: "70vh" }}>
            <BasicTabs
              handleClose={() => setOpen(false)}
              storeData={storeData}
              storeLocation={storeLocation}
              storeLocationRefetch={storeLocationRefetch}
              setStoreData={handleSetStoreData}
              refetch={refetch}
              searchKeyword={searchKeyword}
              itemsPerPage={itemsPerPage}
              skip={skip}
              value={value}
              setValue={setValue}
            />
          </Box>
        </Drawer>
      </Grid>
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          margin: "20px 0",
        }}
      >
        <Grid item xs={12} m={1}>
          <Pagination
            style={{
              display: "flex",
              justifyContent: "center",
              transform: "scale(1.2)",
              margin: "20px 0",
            }}
            count={totalPages}
            page={currentPage}
            onChange={(event, page) => setCurrentPage(page)}
            color="secondary"
          />
        </Grid>
        <Grid item xs={2} justifyContent="space-between" display="flex">
          <FormControl
            variant="outlined"
            size="small"
            sx={{ minWidth: 90, marginLeft: 2 }}
          >
            <Typography fontSize={12} fontWeight="bold">
              &nbsp; Page Size
            </Typography>
            <Select
              value={itemsPerPage}
              onChange={handleItemsPerPageChange}
              sx={{ height: "30px", fontSize: "0.875rem" }}
              inputProps={{ "aria-label": "Without label" }}
              minHeight="20px"
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={20}>20</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Box>
    </DashboardLayout>
  );
}

export default Stores;
