import React, { useContext } from "react";
import AgGrid from "components/MDGrid";
import { ReportsFilter } from ".";

//?NOTE - Tab value against report name
/**
 * 0 => Sales Report
 * 1 => Return / Refund Report
 * 2 => Summary Report
 * 3 => Footfall Report
 * 4 => Stock Report
 * 5 => Event Report
 * 6 => GMV Report
 */
//!ACTION:
/**
 * TODO: Refactor Sales and Return and Refund data as per there tab instead of fetching it in one go in sales report
 */
function ReportTables({ rowData, columnDefs }) {
  const {
    getStockReport,
    tabValue,
    startDate,
    endDate,
    selectedBrand,
    selectedStore,
    getSalesReport,
    key,
  } = useContext(ReportsFilter);

  const fetchFn = () => {
    if (tabValue === 0) {
      return getSalesReport;
    } else if (tabValue === 1) {
      return getSalesReport; // it is compatible for both sales and return report
    } else if (tabValue === 4) {
      return getStockReport;
    }
  };
  // Determine the resultsParser dynamically based on the tabValue
  const resultsParser = (data) => {
    switch (tabValue) {
      case 0:
      case 1:
        return {
          results: data?.getSalesReports?.results || [],
          total: data?.getSalesReports?.total || 0,
        };
      case 4:
        return {
          results: data?.getStockReports?.results || [],
          total: data?.getStockReports?.total || 0,
        };
      default:
        return {
          results: [],
          total: 0,
        };
    }
  };
  return (
    <div className={"ag-theme-quartz-dark"} style={{ height: 500 }}>
      {![0, 1, 4].includes(tabValue) ? (
        <AgGrid
          queryData={rowData || []}
          columns={columnDefs}
          gridProps={{
            rowHeight: 60,
            defaultColDef: {
              enableRowGroup: true,
              enablePivot: true,
              enableValue: true,
              floatingFilter: true,
              sortable: true,
              editable: false,
              filter: true,
              flex: 1,
              minWidth: 200,
            },
            pivotMode: false,
            sideBar: {
              toolPanels: ["columns", "filters"],
            },
            enableRangeSelection: true,
          }}
        />
      ) : (
        <AgGrid
          key={key}
          readMethod={{
            fetchFn: fetchFn(),
            inputParser: ({ startRow, endRow, filter, sortModel }) => {
              return {
                take: endRow - startRow,
                skip: startRow,
                startDate: startDate || null,
                endDate: endDate || null,
                searchCols: Object.keys(filter) || [],
                searchBy: Object.values(filter) || [],
                orderType:
                  tabValue === 0 ? "PURCHASE" : tabValue === 1 ? "RETURN" : "",
                filter: {
                  brandId: selectedBrand || null,
                  storeId: selectedStore || null,
                },
              };
            },
            resultsParser: ({ data }) => resultsParser(data),
          }}
          columns={columnDefs}
          defaultColDefProp={{
            filter: "agTextColumnFilter",
            editable: false,
            filterParams: {
              filterOptions: ["contains"],
              defaultOption: "contains",
              maxNumConditions: 1,
            },
          }}
        />
      )}
    </div>
  );
}

export default ReportTables;
